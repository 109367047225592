// @ts-nocheck
import React, { useCallback } from 'react';
import { ResourceComponentProps } from 'react-admin';
import { useHistory } from 'react-router-dom';

import { List, DataGrid, TextField } from '../../Components/List';

const EmployeeList = (props: ResourceComponentProps) => {
  const { basePath } = props;
  const history = useHistory();

  const handleAddNew = useCallback(() => {
    if (!history) return;
    history.push(`${basePath}/create`);
  }, [history, basePath]);

  return (
    <List {...props} handleAdd={handleAddNew} perPage={50}>
      <DataGrid rowClick="show">
        <TextField source="firstName" label="Vorname" />
        <TextField source="lastName" label="Nachname" />
        <TextField source="email" label="E-Mail" />
        <TextField source="kreditdeURL" label="KREDIT.DE URL" />
      </DataGrid>
    </List>
  );
};

export default EmployeeList;
