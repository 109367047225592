// @ts-nocheck
import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification, setSidebarVisibility, LayoutComponent, LayoutProps, ReduxState } from 'react-admin';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AppBar from './AppBar';
import SideBar from './SideBar';

const theme = createMuiTheme({});

const Layout: LayoutComponent = ({
    children,
    dashboard,
    logout,
    title,
}: LayoutProps) => {
    const dispatch = useDispatch();
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

    useEffect(() => {
        dispatch(setSidebarVisibility(true));
    }, [dispatch]);
    return (
        <ThemeProvider theme={theme}>
            <div>
                <AppBar title={title} open={open} logout={logout} />
                <div className="flex">
                    <SideBar hasDashboard={!!dashboard} />
                    <div className={`content ${open ? '' : 'full'}`}>
                        {children}
                    </div>
                </div>
                <Notification />
            </div>
        </ThemeProvider>
    );
};

export default Layout;