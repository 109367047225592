import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  CreateControllerProps,
  EditControllerProps,
  Identifier,
} from 'ra-core';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import { DataGrid, List } from '../List';
import TextField from '../List/TextField';
import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';
import { ArrayInput, SimpleFormIterator } from 'ra-ui-materialui';
import TextInputField from './TextInputField';

import { useDataProvider } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    line: {
      display: 'flex',
      listStyleType: 'none',
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down('xs')]: { display: 'block' },
      '&.fade-enter': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
      },
      '&.fade-enter-active': {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'all 500ms ease-in',
      },
      '&.fade-exit': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '&.fade-exit-active': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
        transition: 'all 500ms ease-in',
      },
    },
    index: {
      width: '3em !important',
      paddingTop: '1em',
      [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    form: { flex: 2 },
    action: {
      paddingTop: '0.5em',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaSimpleFormIterator' }
);

const BulkSaveButton = (props) => {
  const onClick = () => {
    props.onSave(props.selectedIds);
  };
  return (
    <div className="padding" onClick={onClick}>
      <input
        type="submit"
        className={'btn btn-blue btn-no-margin'}
        value="Speichern"
      />
    </div>
  );
};

type Props = { source: string; btnLabel: string } & (
  | (CreateFormChildrenProps & CreateControllerProps)
  | (EditFormChildrenProps & EditControllerProps)
);

const EmployeesListInputField = (props: Props) => {
  const recordValue = get(props.record, props.source);
  const { source } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [listIds, setListIds] = useState(
    Array.isArray(recordValue)
      ? recordValue.map((emp) => (emp.id ? emp.id : emp._id))
      : []
  );

  const classes = useStyles(props);

  const { input } = useField(props.source);
  const { value, onChange } = input;

  useEffect(() => {
    setListIds(
      Array.isArray(recordValue)
        ? recordValue.map((emp) => (emp._id ? emp._id : emp.id))
        : []
    );
  }, [recordValue]);

  useEffect(() => {
    // @ts-ignore
    const values = Array.isArray(value)
      ? value.map((val) =>
          typeof val === 'string' ? val : val?._id ? val._id : val.id
        )
      : [];
    setListIds(values);
  }, [value, props.source]);

  const onSelect = useCallback(
    (ids: Identifier[]) => {
      const values = [...listIds, ids];
      onChange(values);
    },
    [onChange, listIds]
  );

  const onToggleItem = useCallback(
    (id: Identifier) => {
      onChange([...listIds, id]);
    },
    [onChange, listIds]
  );

  const dataProvider = useDataProvider();

  const onSave = async (ids: string[]) => {
    const response = await dataProvider.getMany('employees', {
      ids,
    });
    onChange(response.data);
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onBackdropClick={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">{props.btnLabel}</DialogTitle>
        <DialogContent>
          {/* @ts-ignore */}
          <List
            {...props}
            resource="employees"
            bulkActionButtons={
              <BulkSaveButton {...props} selectedIds={value} onSave={onSave} />
            }
            perPage={15}
            sort={{ field: 'firstName', order: 'DESC' }}
            basePath={source}
            title={source}
          >
            <DataGrid
              rowClick="toggleSelection"
              selectedIds={listIds}
              onSelect={onSelect}
              onToggleItem={onToggleItem}
            >
              {/* @ts-ignore */}
              <TextField source="firstName" />
              {/* @ts-ignore */}
              <TextField source="lastName" />
              {/* @ts-ignore */}
              <TextField source="email" />
            </DataGrid>
          </List>
        </DialogContent>
      </Dialog>
      <ArrayInput source={props.source} disabled={!props.isEditMode}>
        <SimpleFormIterator addButton={<></>} classes={classes}>
          <TextInputField
            {...props}
            source="firstName"
            label="Vorname"
            disabled
          />
          <TextInputField
            {...props}
            source="lastName"
            label="Nachname"
            disabled
          />
          <TextInputField {...props} source="email" label="E-Mail" disabled />
        </SimpleFormIterator>
      </ArrayInput>
      {props.isEditMode == null ||
        (props.isEditMode === true && (
          <>
            <input
              onClick={() => setIsDialogOpen(!isDialogOpen)}
              type="button"
              className={'btn btn-blue btn-no-margin'}
              value={props.btnLabel}
            />
            <p></p>
          </>
        ))}
    </>
  );
};

export default EmployeesListInputField;
