import React from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({});

const LoginPage = () => {
  const login = useLogin();
  const notify = useNotify();
  const submit = (e: any) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    login({ email, password }).catch(() => notify('Invalid email or password'));
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="login">
        <div className="flex">
          <div className="left">
            <img className="logo" src="/img/kredit-de.svg" alt="Logo von KREDIT DE" title="KREDIT DE" />
            <h1>Herzlich willkommen im KREDIT DE STANDORT CRM Backend.</h1>
          </div>
          <div className="right">
            <h2>Einloggen</h2>
            <form method="post" onSubmit={submit}>
              <label>
                E-Mail
                <input name="email" type="email" />
              </label>
              <label>
                Passwort
                <input name="password" type="password" />
              </label>
              <button type="submit" className="btn">
                Einloggen
              </button>
            </form>
          </div>
        </div>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

export default LoginPage;
