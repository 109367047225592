// @ts-nocheck
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import List from '../../Components/List/List';
import DataGrid from '../../Components/List/DataGrid';
import TextField from '../../Components/List/TextField';
import DateField from '../../Components/List/DateField';

import WebsitesInlineEdit from './WebsitesInlineEdit';

const defaultSort = { field: 'createdAt', order: 'DESC' };

const WebsiteList = (props) => {
  const { basePath } = props;
  const history = useHistory();
  const addNewCallback = useCallback(() => {
    if (!history) return;
    history.push(`${basePath}/create`);
  }, [history, basePath]);

  return (
    <List {...props} sort={defaultSort} legendData={{}} perPage={20} handleAdd={addNewCallback}>
      <DataGrid rowClick="expand" expand={WebsitesInlineEdit} expandClass="edit-broker show" tableClassName="broker-table">
        <TextField source="domain" label="Domain" />
        <DateField source="createdAt" label="Angelegt am" notSearchable sortable />
      </DataGrid>
    </List>
  );
};

export default WebsiteList;
