import React from 'react';
import { CreateControllerProps, EditControllerProps } from 'ra-core';
import { ArrayInput, SimpleFormIterator } from 'ra-ui-materialui';
import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';
import MarkdownInput from 'ra-input-markdown';
import MidInputField from './MidInputField';
import { makeStyles } from '@material-ui/core/styles';
import TextAreaInputField from './TextAreaInputField';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    line: {
      display: 'flex',
      listStyleType: 'none',
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down('xs')]: { display: 'block' },
      '&.fade-enter': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
      },
      '&.fade-enter-active': {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'all 500ms ease-in',
      },
      '&.fade-exit': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '&.fade-exit-active': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
        transition: 'all 500ms ease-in',
      },
    },
    index: {
      width: '3em !important',
      paddingTop: '1em',
      [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    form: { flex: 2 },
    action: {
      paddingTop: '0.5em',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaSimpleFormIterator' }
);

type Props = (CreateFormChildrenProps & CreateControllerProps) | (EditFormChildrenProps & EditControllerProps);

const TextblockInput = (props: Props) => {
  const classes = useStyles(props);
  return (
    <ArrayInput source="textblocks" disabled={!props.isEditMode}>
      <SimpleFormIterator classes={classes}>
        <MidInputField {...props} source="mid" label="MID" />
        {props.isEditMode === true ? (
          <MarkdownInput {...props} source="markdown" label="Markdown" />
        ) : (
          <TextAreaInputField source="markdown" label="Markdown" {...props} />
        )}
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default TextblockInput;
