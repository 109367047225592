import React from 'react';
import { Record } from 'react-admin';

interface Props {
  record: Record;
  basePath: string;
  resource: string;
  field: any;
  handleToggleExpand: () => void;
}

const DataGridCell = (props: Props) => {
  const { record, basePath, resource, field, handleToggleExpand } = props;
    return (
        <td>
            {React.cloneElement<Props>(field, {
                record,
                basePath: field.props.basePath || basePath,
                resource,
                handleToggleExpand
            })}
        </td>
    )
}

export default DataGridCell;