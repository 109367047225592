// @ts-nocheck
import React, { useCallback } from 'react';
import { ResourceProps } from 'ra-core';
import { List, DataGrid, TextField } from '../../Components/List';
import EnumReplaceField from '../../Components/List/EnumReplaceField';
import { officeTypes } from '../../utils/types';
import { useHistory } from 'react-router-dom';
import BooleanField from '../../Components/List/BooleanField';

const OfficeList = (props: ResourceProps) => {
  const { basePath } = props;
  const history = useHistory();

  const handleAddNew = useCallback(() => {
    if (!history) return;
    history.push(`${basePath}/create`);
  }, [history, basePath]);

  return (
    <List {...props} handleAdd={handleAddNew} perPage={25}>
      <DataGrid rowClick="show">
        <BooleanField source="isActivated" label="Ist Aktiv" notSearchable />
        <EnumReplaceField source="type" replace={officeTypes} label="Typ" />
        <TextField source="kreditdeURL" label="KREDIT.DE URL" />
        <TextField source="address.city.ort" label="Ort" />
        <TextField source="address.city.plz" label="PLZ" />
      </DataGrid>
    </List>
  );
};

export default OfficeList;
