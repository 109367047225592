import React from 'react';
import { EditControllerProps, useNotify, useRefresh } from 'ra-core';
import { useEditController } from 'react-admin';
import EditForm, {
  EditFormChildrenProps,
} from '../../Components/Form/EditForm';
import SubmitButton from '../../Components/Form/SubmitButton';
import TextInputField from '../../Components/Form/TextInputField';
import DeleteButton from '../../Components/DeleteButton';
import PatchPartnerSchema from './schemas/patchPartner.json';
import CityInput from '../../Components/Form/CityInput';

const PartnerEdit = (props: EditControllerProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const editControllerProps = useEditController({
    ...props,
    mutationMode: 'pessimistic',
    onFailure: () => {
      notify('Da lief etwas falsch :(', 'error');
      refresh();
    },
    onSuccess: () => {
      notify('Erfolgreich gespeichert!', 'success');
      refresh();
    },
  });
  return (
    <div className={'sale details'}>
      <div className="flex space">
        <h2 className="symbol">Partner</h2>
      </div>
      <div className="flex row">
        <div className="flex-item">
          <EditForm
            formLabel="Übersicht"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
            validationSchema={PatchPartnerSchema}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <TextInputField
                    source="firstName"
                    label="Vorname"
                    {...props}
                  />
                  <TextInputField
                    source="lastName"
                    label="Nachname"
                    {...props}
                  />
                  <TextInputField
                    source="email"
                    label="E-Mail-Adresse"
                    {...props}
                  />
                  <TextInputField
                    source="phoneNumber"
                    label="Telefonnummer"
                    {...props}
                  />
                  <TextInputField
                    source="organisation"
                    label="Firma / Organisation"
                    {...props}
                  />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
        <div className="flex-item">
          <EditForm
            formLabel="Adresse"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
            validationSchema={PatchPartnerSchema}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <CityInput {...props} {...editControllerProps} notAddress={true} />
                  <TextInputField
                    source="street"
                    label="Straße"
                    {...props}
                  />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>
      {editControllerProps.record && <DeleteButton {...editControllerProps} />}
    </div>
  );
};


export default PartnerEdit;
