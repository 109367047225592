import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
} from 'react';
import Select, { ActionMeta, Styles, ValueType } from 'react-select';
import {
  ResourceComponentProps,
  useListContext,
  ListContextProvider,
  useListController,
  ListProps,
} from 'react-admin';
import Pagination from './Pagination';
import { DataGridProps } from './DataGrid';
import Legend from './Legend';

interface LP {
  legendData?: Array<{ label: string; color: string }>;
  handleAdd?: () => void;
  hidePagination?: boolean;
  bulkActionsButton?:
    | false
    | ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const customStyles: Styles<{}, false> = {
  control: (provided) => ({
    ...provided,
    minwidth: 100,
    width: 100,
    borderRadius: 0,
    border: '1px solid #E5E5E5',
  }),

  placeholder: (provided) => ({
    ...provided,
    opacity: 1,
    color: '#aaa',
  }),
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
  }),
};

const options = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

interface Option {
  label: string;
  value: number;
}

const ListView: React.FunctionComponent<ListProps & LP> = (props) => {
  const {
    hidePagination = false,
    children,
    legendData,
    handleAdd,
    bulkActionButtons,
  } = props;
  const listContext = useListContext(props);
  const { selectedIds, perPage, setPerPage, total, page, ...rest } =
    listContext;
  // TODO die typen sind falsch, er denkt value ist vom type Option, aber es ist direkt der value
  const onChange = ({
    value,
    action,
  }: {
    value: ValueType<Option, false>;
    action: ActionMeta<Option>;
  }) => {
    // @ts-ignore
    setPerPage(value);
  };
  const range = ` ${(page - 1) * perPage + 1}-${
    page * perPage
  } von ${total} Ergebnissen`;

  return (
    <div>
      {legendData || handleAdd || bulkActionButtons ? (
        <Legend
          {...listContext}
          bulkActionButtons={bulkActionButtons}
          handleAdd={handleAdd}
          legendData={legendData}
        />
      ) : null}
      {children &&
        isValidElement(children) &&
        cloneElement<DataGridProps>(Children.only(children), {
          ...rest,
          hasBulkActions: bulkActionButtons != null,
          bulkActionButtons,
          ...children.props,
        })}
      {!hidePagination && (
        <>
          <div className="dataTables_info" role="status">
            Ergebnisse pro Seite
            <Select
              menuPlacement="top"
              isSearchable={false}
              styles={customStyles}
              options={options}
              onChange={onChange}
              value={{ value: perPage, label: `${perPage}` }}
            />{' '}
            {range}
          </div>
          <Pagination {...listContext} />
        </>
      )}
    </div>
  );
};

export const List = (props: ResourceComponentProps & ListProps) => (
  <ListContextProvider value={useListController(props)}>
    <ListView {...props} />
  </ListContextProvider>
);

export default List;
