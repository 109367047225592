import React from 'react';
import { EditControllerProps, useNotify, useRefresh } from 'ra-core';
import { useEditController } from 'react-admin';
import EditForm, {
  EditFormChildrenProps,
} from '../../Components/Form/EditForm';
import Pictures from '../../Components/Form/Pictures';
import SubmitButton from '../../Components/Form/SubmitButton';
import TextInputField from '../../Components/Form/TextInputField';
import SelectInputField from '../../Components/Form/SelectInputField';
import { officeTypes, productTypes } from '../../utils/types';
import CityInput from '../../Components/Form/CityInput';
import BusinessHoursInputField from '../../Components/Form/BusinessHoursField';
import TextblockInput from '../../Components/Form/TextblockInput';
import DeleteButton from '../../Components/DeleteButton';
import DateInputField from '../../Components/Form/DateInputField';
import BooleanInputField from '../../Components/Form/BooleanInputField';
import PostalCodeAreaInputField from '../../Components/Form/PostalCodeAreaInputField';
import MapInput from '../../Components/Form/MapInput';
import EmployeesListInputField from '../../Components/Form/EmployeesListInputField';

const OfficeEdit = (props: EditControllerProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const editControllerProps = useEditController({
    ...props,
    mutationMode: 'pessimistic',
    onFailure: () => {
      notify('Da lief etwas falsch :(', 'error');
      refresh();
    },
    onSuccess: () => {
      notify('Erfolgreich gespeichert!', 'success');
      refresh();
    },
  });
  return (
    <div className={'sale details'}>
      <div className="flex space">
        <h2 className="symbol">Standort</h2>
      </div>
      <div className="flex row">
        <div className="flex-item">
          <EditForm
            formLabel="Übersicht"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <SelectInputField
                    source="type"
                    label="Typ"
                    availableOptions={officeTypes}
                    {...props}
                  />
                  <SelectInputField
                    source="products"
                    label="Produkte"
                    availableOptions={productTypes}
                    isMulti
                    {...props}
                  />
                  <TextInputField
                    {...props}
                    source="kreditdeURL"
                    label="KREDIT.DE URL"
                  />
                  <BooleanInputField
                    {...props}
                    source="default"
                    label="Default Standort"
                  />
                  <BooleanInputField
                    {...props}
                    source="isActivated"
                    label="Ist Aktiv"
                  />
                  <TextInputField {...props} source="title" label="Titel" />
                  <TextInputField
                    {...props}
                    source="email"
                    label="E-Mail Adresse"
                  />
                  <TextInputField
                    {...props}
                    source="phoneNumber"
                    label="Telefonnummer"
                  />
                  <TextInputField
                    {...props}
                    source="urlDataProtection"
                    label="URL Datenschutz"
                  />
                  <TextInputField
                    {...props}
                    source="urlSiteNotice"
                    label="URL Impressum"
                  />
                  <BusinessHoursInputField
                    source="businessHours"
                    label="Öffnungszeiten"
                    additionalLabel
                    {...props}
                    {...editControllerProps}
                  />
                  <PostalCodeAreaInputField
                    source="responsibleArea"
                    label="PLZ Bereich"
                    additionalLabel
                    {...props}
                    {...editControllerProps}
                  />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
        <div className="flex-item">
          <EditForm
            formLabel="Adresse"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <CityInput {...props} {...editControllerProps} />
                  <TextInputField
                    {...props}
                    source="address.street"
                    label="Straße"
                  />
                  <TextInputField {...props} source="address.alternativeGoogleMapsAddress" label="Alternative Google Maps Address" />
                  <MapInput
                    {...props}
                    source="address.mapCoords"
                    label="Map Koordinaten"
                  />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>
      <div className="flex row">
        <div className="flex-item">
          <EditForm
            formLabel="Bilder"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <Pictures {...props} {...editControllerProps} />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
        <div className="flex-item">
          <EditForm
            formLabel="Managers"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <EmployeesListInputField
                    {...editControllerProps}
                    {...props}
                    source="managers"
                    btnLabel="Managers auswählen"
                  />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>
      <div className="flex row">
        <div className="flex-item">
          <EditForm
            formLabel="Textblöcke"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <TextblockInput {...editControllerProps} {...props} />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
        <div className="flex-item">
          <EditForm
            formLabel="Angestellte"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <EmployeesListInputField
                    {...editControllerProps}
                    {...props}
                    source="employees"
                    btnLabel="Angestellte auswählen"
                  />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>
      {editControllerProps.record && <DeleteButton {...editControllerProps} />}
    </div>
  );
};

export default OfficeEdit;
