// @ts-nocheck
import React, { isValidElement, useCallback, Fragment, cloneElement, createElement } from 'react';
import { Checkbox } from '@material-ui/core';
import { linkToRecord, useExpanded } from 'ra-core';
import { useHistory } from 'react-router-dom';
import DataGridCell from './DataGridCell';

const DataGridRow = (props) => {
  const { children, record, resource, id, basePath, rowClick, handleToggleSelection, expand, expandClass, onToggleItem } = props;
  const history = useHistory();
  const [expanded, toggleExpanded] = useExpanded(resource, id);

  const handleToggleExpand = useCallback(
    (event) => {
      toggleExpanded();
      event.stopPropagation();
    },
    [toggleExpanded]
  );

  const handleClick = useCallback(
    async (event) => {
      if (!rowClick) return;
      event.persist();

      const effect = typeof rowClick === 'function' ? await rowClick(id, basePath, record) : rowClick;
      switch (effect) {
        case 'edit':
          history.push(linkToRecord(basePath, id)); /*  */
          return;
        case 'show':
          history.push(linkToRecord(basePath, id, 'show'));
          return;
        case 'expand':
          handleToggleExpand(event);
          return;
        case 'toggleSelection':
          onToggleItem(id);
          return;
        default:
          if (effect) history.push(effect);
          return;
      }
    },
    [basePath, onToggleItem, history, handleToggleExpand, id, record, rowClick]
  );

  return (
    <Fragment>
      <tr onClick={handleClick}>
        {props.bulkActionButtons && (
          <Checkbox
            color="primary"
            className={`select-item`}
            checked={(props.selectedIds as string[]).includes(id)}
            onClick={handleToggleSelection}
          />
        )}
        {React.Children.map(children, (field, index) => (
          <DataGridCell
            key={`${id}-${field.props.source || index}`}
            record={record}
            Expand={expand && handleToggleExpand}
            {...{ field, basePath, resource }}
          />
        ))}
      </tr>
      {expand && expanded && (
        <tr className={expandClass}>
          {isValidElement(expand)
            ? cloneElement(expand, {
                // @ts-ignore
                record,
                basePath,
                resource,
                id: String(id),
              })
            : createElement(expand, {
                record,
                basePath,
                resource,
                id: String(id),
              })}
        </tr>
      )}
    </Fragment>
  );
};

export default DataGridRow;
