import React from 'react';
import { useCreateController } from 'react-admin';
import CreateForm, {
  CreateFormChildrenProps,
} from '../../Components/Form/CreateForm';
import SubmitButton from '../../Components/Form/SubmitButton';
import TextInputField from '../../Components/Form/TextInputField';
import Pictures from '../../Components/Form/Pictures';
import SelectInputField from '../../Components/Form/SelectInputField';
import { officeTypes, productTypes } from '../../utils/types';
import CityInput from '../../Components/Form/CityInput';
import BusinessHoursInputField from '../../Components/Form/BusinessHoursField';
import PostalCodeAreaInputField from '../../Components/Form/PostalCodeAreaInputField';
import TextblockInput from '../../Components/Form/TextblockInput';
import MapInput from '../../Components/Form/MapInput';
import BooleanInputField from '../../Components/Form/BooleanInputField';
import EmployeesListInputField from '../../Components/Form/EmployeesListInputField';
import PostOfficeSchema from './schemas/postOffice.json';

const OfficeCreate = (props) => {
  const createProps = useCreateController(props);
  return (
    <div className="sell details">
      <div className="flex space">
        <h2 className="symbol">Standort Anlegen</h2>
      </div>
      <CreateForm {...createProps} validationSchema={PostOfficeSchema}>
        {(props: CreateFormChildrenProps) => {
          return (
            <>
              <div className="flex row">
                <div className="flex-item">
                  <div className={'shadow-box overview'}>
                    <h3 className="underlined">Übersicht</h3>
                    <div className="details-info">
                      <SelectInputField
                        {...props}
                        source="type"
                        label="Typ"
                        availableOptions={officeTypes}
                      />
                      <SelectInputField
                        {...props}
                        source="products"
                        label="Produkte"
                        availableOptions={productTypes}
                        isMulti
                      />
                      <TextInputField
                        {...props}
                        source="kreditdeURL"
                        label="KREDIT.DE URL"
                      />
                      <BooleanInputField
                        {...props}
                        source="isActivated"
                        label="Ist Aktiv"
                      />
                      <TextInputField {...props} source="title" label="Titel" />
                      <TextInputField
                        {...props}
                        source="email"
                        label="E-Mail Adresse"
                      />
                      <TextInputField
                        {...props}
                        source="phoneNumber"
                        label="Telefonnummer"
                      />
                      <TextInputField
                        {...props}
                        source="urlDataProtection"
                        label="URL Datenschutz"
                        defaultValue="https://www.kredit.de/datenschutz/"
                      />
                      <TextInputField
                        {...props}
                        source="urlSiteNotice"
                        label="URL Impressum"
                        defaultValue="https://www.kredit.de/impressum/"
                      />
                      <BusinessHoursInputField
                        source="businessHours"
                        label="Öffnungszeiten"
                        additionalLabel
                        {...props}
                      />
                      <PostalCodeAreaInputField
                        source="responsibleArea"
                        label="PLZ Bereich"
                        additionalLabel
                        {...props}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-item">
                  <div className={'shadow-box overview'}>
                    <h3 className="underlined">Adresse</h3>
                    <div className="details-info">
                      <CityInput {...createProps} {...props} />
                      <TextInputField
                        {...props}
                        source="address.street"
                        label="Straße"
                      />
                      <TextInputField {...props} source="address.alternativeGoogleMapsAddress" label="Alternative Google Maps Address" />
                      <MapInput
                        {...props}
                        source="address.mapCoords"
                        label="Map Koordinaten"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex row">
                <div className="flex-item">
                  <div className={'shadow-box overview'}>
                    <h3 className="underlined">Bilder</h3>
                    <div className="details-info">
                      <Pictures {...createProps} {...props} />
                    </div>
                  </div>
                </div>
                <div className="flex-item">
                  <div className={'shadow-box overview'}>
                    <h3 className="underlined">Managers</h3>
                    <div className="details-info">
                      <EmployeesListInputField
                        {...createProps}
                        {...props}
                        source="managers"
                        btnLabel="Managers auswählen"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex row">
                <div className="flex-item">
                  <div className={'shadow-box overview'}>
                    <h3 className="underlined">Textblöcke</h3>
                    <TextblockInput {...createProps} {...props} />
                  </div>
                </div>
                <div className="flex-item">
                  <div className={'shadow-box overview'}>
                    <h3 className="underlined">Angestellte</h3>
                    <EmployeesListInputField
                      {...createProps}
                      {...props}
                      source="employees"
                      btnLabel="Angestellte auswählen"
                    />
                  </div>
                </div>
              </div>
              <SubmitButton {...props} isLeft={true} />
            </>
          );
        }}
      </CreateForm>
    </div>
  );
};

export default OfficeCreate;
