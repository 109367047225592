
export const convertFileToBase64 = async (file: File, flags: object = {}) => {
  const f = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

  return {
    image: f,
    ...flags
  };
}

