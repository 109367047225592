// @ts-nocheck
import React from 'react';
import get from 'lodash/get';

const BooleanField = ({ record, source, isEditMode, transform = null }) => {
  const val = get(record, source);
  const value =
    transform != null && typeof transform === 'function' ? transform(val) : val;
  return (
    <label className="edit">
      {!isEditMode && value && <i className="icon icon-check verified"></i>}
      {!isEditMode && (value == null || value === false) && (
        <i className="icon icon-clear notVerified"></i>
      )}
    </label>
  );
};

export default BooleanField;
