import { ResourceProps } from 'react-admin';
import PartnerCreate from './PartnerCreate';
import PartnerEdit from './PartnerEdit';
import PartnerList from './PartnerList';

const Partner: ResourceProps = {
  name: 'partners',
  options: { label: 'Partner' },
  list: PartnerList,
  create: PartnerCreate,
  edit: PartnerEdit
};

export default Partner;
