import React from 'react';
import { useCreateController } from 'react-admin';
import CreateForm, { CreateFormChildrenProps } from '../../Components/Form/CreateForm';
import SubmitButton from '../../Components/Form/SubmitButton';
import TextInputField from '../../Components/Form/TextInputField';
import Pictures from '../../Components/Form/Pictures';
import PostEmployeeSchema from './schemas/postEmployee.json';
import SelectInputField from '../../Components/Form/SelectInputField';
import { genders } from '../../utils/types';

const EmployeeCreate = (props) => {
  const createProps = useCreateController(props);

  return (
    <div className="sell details">
      <div className="flex space">
        <h2 className="symbol">Person Anlegen</h2>
      </div>
      <CreateForm {...createProps} validationSchema={PostEmployeeSchema}>
        {(props: CreateFormChildrenProps) => {
          return (
            <div className="flex row">
              <div className="flex-item">
                <div className={'shadow-box overview'}>
                  <h3 className="underlined">Übersicht</h3>
                  <div className="details-info">
                    <SelectInputField {...props} name="gender" source="gender" label="Geschlecht" availableOptions={genders} />
                    <TextInputField {...props} source="firstName" label="Vorname" />
                    <TextInputField {...props} source="lastName" label="Nachname" />
                    <TextInputField {...props} source="email" label="E-Mail" />
                    <TextInputField {...props} source="phoneNumber" label="Telefonnummer" />
                    <TextInputField {...props} source="shortTitle" label="Kurztitel" />
                    <TextInputField {...props} source="shortDescription" label="Kurzbeschreibung" />
                  </div>
                </div>
              </div>
              <div className="flex-item">
                <div className={'shadow-box overview'}>
                  <h3 className="underlined">Bilder</h3>
                  <div className="details-info">
                    <Pictures {...createProps} {...props} />
                  </div>
                </div>
              </div>
              <SubmitButton {...props} isLeft={true} />
            </div>
          );
        }}
      </CreateForm>
    </div>
  );
};

export default EmployeeCreate;
