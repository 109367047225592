import React from 'react';
import { useCreateController } from 'react-admin';
import CreateForm, { CreateFormChildrenProps } from '../../Components/Form/CreateForm';
import SubmitButton from '../../Components/Form/SubmitButton';
import SelectInputField from '../../Components/Form/SelectInputField';
import {  productTypes } from '../../utils/types';
import ManagerInput from '../../Components/Form/ManagerInput';

const ProductManagerCreate = (props) => {
  const createProps = useCreateController(props);

  return (
    <div className="sell details">
      <div className="flex space">
        <h2 className="symbol">Standard Produkt Manager Anlegen</h2>
      </div>
      <CreateForm {...createProps}>
        {(props: CreateFormChildrenProps) => {
          return (
            <div className="flex row">
              <div className="flex-item">
                <div className={'shadow-box overview'}>
                  <h3 className="underlined">Übersicht</h3>
                  <div className="details-info">
                  <SelectInputField
                    {...createProps}
                    {...props}
                    name="products"
                    source="products"
                    label="Produkte"
                    availableOptions={productTypes}
                    isMulti={true}
                  />
                  <ManagerInput {...props} {...createProps} />
                  </div>
                </div>
              </div>
              <SubmitButton {...props} isLeft={true} />
            </div>
          );
        }}
      </CreateForm>
    </div>
  );
};

export default ProductManagerCreate;
