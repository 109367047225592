import React from 'react';
import { EditControllerProps, useNotify, useRefresh } from 'ra-core';
import { useEditController } from 'react-admin';
import EditForm, {
  EditFormChildrenProps,
} from '../../Components/Form/EditForm';
import Pictures from '../../Components/Form/Pictures';
import SubmitButton from '../../Components/Form/SubmitButton';
import TextInputField from '../../Components/Form/TextInputField';
import DeleteButton from '../../Components/DeleteButton';
import SelectInputField from '../../Components/Form/SelectInputField';
import { genders, productTypes } from '../../utils/types';
import ManagerInput from '../../Components/Form/ManagerInput';

const ProductManagerEdit = (props: EditControllerProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const editControllerProps = useEditController({
    ...props,
    mutationMode: 'pessimistic',
    onFailure: () => {
      notify('Da lief etwas falsch :(', 'error');
      refresh();
    },
    onSuccess: () => {
      notify('Erfolgreich gespeichert!', 'success');
      refresh();
    },
  });
  return (
    <div className={'sale details'}>
      <div className="flex space">
        <h2 className="symbol">Standard Produkt Manager</h2>
      </div>
      <div className="flex row">
        <div className="flex-item">
          <EditForm
            formLabel="Übersicht"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
          >
            {(editProps: EditFormChildrenProps) => {
              return (
                <>
                  <SelectInputField
                    {...editProps}
                    name="products"
                    source="products"
                    label="Produkte"
                    availableOptions={productTypes}
                    isMulti={true}
                  />
                  <ManagerInput {...props} {...editProps} />
                  <SubmitButton {...editProps} {...props}  isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>
      {editControllerProps.record && <DeleteButton {...editControllerProps} />}
    </div>
  );
};

export default ProductManagerEdit;
