import React from 'react';
import { shallowEqual } from 'react-redux';
import { useInput, Labeled, InputHelperText, CreateControllerProps, EditControllerProps } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FileInputPreview from './FileInputPreview';
import EnhancedImageField from './EnhancedImageFieldInput';
import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';

const useStyles = makeStyles(
  (theme) => ({
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {},
    removeButton: {
      width: '100%',
    },
    root: { width: '100%' },
  }),
  { name: 'RaFileInput' }
);

interface AddProps {
  disableDropzone?: boolean;
  onRemove?: (file: any) => void;
}

type Props =
  | (CreateFormChildrenProps & CreateControllerProps & AddProps & { source?: string })
  | (EditFormChildrenProps & EditControllerProps & AddProps & { source?: string });

const Pictures = (props: Props) => {
  const { resource } = props;
  const classes = useStyles(props);

  const transformFile = (file) => {
    if (!(file instanceof File)) {
      return file;
    }

    const source = 'url';
    const preview = URL.createObjectURL(file);
    const transformedFile = {
      rawFile: file,
      [source]: preview,
      pictureType: '',
      pictureName: '',
      title: '',
    };

    return transformedFile;
  };

  const transformFiles = (files: any[]) => {
    if (!files) {
      return [];
    }

    if (Array.isArray(files)) {
      return files.map(transformFile);
    }

    return transformFile(files);
  };

  // useInput
  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = useInput({
    format: transformFiles,
    parse: transformFiles,
    source: props.source ?? 'pictures',
    type: 'file',
  });
  const { touched, error, submitError } = meta;
  const files = value ? (Array.isArray(value) ? value : [value]) : [];

  const onDrop = (newFiles, rejectedFiles, event) => {
    const updatedFiles = [...files, ...newFiles];

    onChange(updatedFiles);
  };

  const onRemove = (file) => () => {
    const filteredFiles = files.filter((stateFile) => !shallowEqual(stateFile, file));

    if (props.onRemove && typeof props.onRemove === 'function') {
      props.onRemove(file);
    } else {
      onChange(filteredFiles as any);
    }
  };

  // dropzone
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Labeled id={id} label={''} source={'pictures'} resource={resource} isRequired={isRequired} meta={meta} className={classes.root}>
      <>
        {props.disableDropzone !== true && (props.isEditMode == null || props.isEditMode === true) ? (
          <div data-testid="dropzone" className={classes.dropZone} {...getRootProps()}>
            <input
              id={id}
              {...getInputProps({
                ...inputProps,
              })}
            />
            <p>Bilder hochladen</p>
          </div>
        ) : (
          <></>
        )}
        <FormHelperText>
          <InputHelperText touched={touched ?? false} error={error || submitError} helperText={''} />
        </FormHelperText>
        <div className="previews">
          {files.map((file, index) => (
            <FileInputPreview
              key={index}
              file={file}
              onRemove={onRemove(file)}
              className={classes.removeButton}
              isEditMode={props.isEditMode}
            >
              <EnhancedImageField record={file} className={classes.preview} index={index} isEditMode={props.isEditMode} />
            </FileInputPreview>
          ))}
        </div>
      </>
    </Labeled>
  );
};

export default Pictures;
