import * as React from 'react';
import { FC } from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import {
  sanitizeFieldRestProps,
  PublicFieldProps,
  InjectedFieldProps,
} from 'react-admin';
import config from '../../config';

const useStyles = makeStyles(
  {
    list: {
      display: 'flex',
      listStyleType: 'none',
    },
    image: {
      margin: '0.5rem',
      maxHeight: '5rem',
    },
  },
  { name: 'RaImageField' }
);

export interface ImageFieldProps extends PublicFieldProps, InjectedFieldProps {
  src?: string;
  sourceType?: string;
  classes?: object;
  record: any;
  className?: string;
  emptyText?: string;
  source?: string;
  title?: string;
}

const EnhancedImageField: FC<ImageFieldProps> = (props) => {
  const {
    className,
    classes: classesOverride,
    source = 'url',
    src,
    title,
    record,
    ...rest
  } = props;
  let sourceValue = get(record, source);
  const classes = useStyles(props);

  // sourceValue = props.isEditMode != null && record.rawFile == null ? `${config.URL}${sourceValue}` : sourceValue;
  sourceValue = `${config.URL}${sourceValue}`;

  // @ts-ignore
  const titleValue = get(record, title) || title;

  return (
    <>
      <div className={className} {...sanitizeFieldRestProps(rest)}>
        <img
          title={titleValue}
          alt={titleValue}
          src={sourceValue}
          className={classes.image}
        />
      </div>
    </>
  );
};

// What? TypeScript loses the displayName if we don't set it explicitly
EnhancedImageField.displayName = 'ImageField';

export default EnhancedImageField;
