export const officeTypes = {
  branch: 'Niederlassung',
  office: 'Geschäftsstelle',
  headquarters: 'Hauptsitz',
  site: 'Standort'
};

export const genders = {
  male: 'Herr',
  female: 'Frau',
  diverse: 'Divers',
};

export const productTypes = {
  privateCredit: 'Privatkredit',
  baufinanzierung: 'Baufinanzierung',
  carCredit: 'Autokredit',
  bailCredit: 'Kautionskredit',
  watchCredit: 'Uhrenkredit',
  refinancing: 'Umschuldung',
};
