// @ts-nocheck
import React from 'react';
import { Field } from 'react-final-form';
import get from 'lodash/get';

import { isValidPostalCode } from '../../utils/isValidPostalCode';

const PostalCodeAreaInputField = (props) => {
  const { source, label, record, additionalLabel = false, isEditMode = false } = props;
  const val = get(record, source);
  const postalCodes = Array.isArray(val) ? val.join('\n') : val;

  const valid = (value) => {
    return isValidPostalCode(Array.isArray(value) ? value.join('\n') : value) ? undefined : 'Fehlerhaft';
  };

  const format = (value) => (Array.isArray(value) ? value.join('\n') : value);
  return (
    <>
      {isEditMode && (
        <Field name={source} validate={valid} format={format} parse={format}>
          {({ input, meta }) => {
            return (
              <>
                {additionalLabel && <p className="grey">{label}:</p>}
                <textarea
                  disabled={!props.isEditMode}
                  {...input}
                  className="textarea"
                  placeholder={label}
                  style={{ borderColor: meta.valid ? 'green' : 'red' }}
                />
                {(!meta.valid || meta.error || meta.submitError) && <span className="error">{meta.error || meta.submitError}</span>}
              </>
            );
          }}
        </Field>
      )}
      {!isEditMode && (
        <Field name={source} format={format} parse={format}>
          {({ input, meta }) => (
            <>
              {additionalLabel && <p className="grey">{label}:</p>}
              <textarea disabled={!props.isEditMode} {...input} className="textarea" placeholder={label} />
              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
            </>
          )}
        </Field>
      )}
    </>
  );
};

export default PostalCodeAreaInputField;
