import React from 'react';
import { EditControllerProps, useNotify, useRefresh } from 'ra-core';
import { useEditController } from 'react-admin';
import EditForm, {
  EditFormChildrenProps,
} from '../../Components/Form/EditForm';
import Pictures from '../../Components/Form/Pictures';
import SubmitButton from '../../Components/Form/SubmitButton';
import TextInputField from '../../Components/Form/TextInputField';
import DeleteButton from '../../Components/DeleteButton';
import PatchEmployeeSchema from './schemas/patchEmployee.json';
import SelectInputField from '../../Components/Form/SelectInputField';
import { genders } from '../../utils/types';

const EmployeeEdit = (props: EditControllerProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const editControllerProps = useEditController({
    ...props,
    mutationMode: 'pessimistic',
    onFailure: () => {
      notify('Da lief etwas falsch :(', 'error');
      refresh();
    },
    onSuccess: () => {
      notify('Erfolgreich gespeichert!', 'success');
      refresh();
    },
  });
  return (
    <div className={'sale details'}>
      <div className="flex space">
        <h2 className="symbol">Personen</h2>
      </div>
      <div className="flex row">
        <div className="flex-item">
          <EditForm
            formLabel="Übersicht"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
            validationSchema={PatchEmployeeSchema}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <SelectInputField
                    {...props}
                    name="gender"
                    source="gender"
                    label="Geschlecht"
                    availableOptions={genders}
                  />
                  <TextInputField
                    source="firstName"
                    label="Vorname"
                    {...props}
                  />
                  <TextInputField
                    source="lastName"
                    label="Nachname"
                    {...props}
                  />
                  <TextInputField
                    source="email"
                    label="E-Mail-Adresse"
                    {...props}
                  />
                  <TextInputField
                    source="phoneNumber"
                    label="Telefonnummer"
                    {...props}
                  />
                  <TextInputField
                    source="shortTitle"
                    label="Titel"
                    {...props}
                  />
                  <TextInputField
                    source="shortDescription"
                    label="Kurzbeschreibung"
                    {...props}
                  />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
        <div className="flex-item">
          <EditForm
            formLabel="Bilder"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
            validationSchema={PatchEmployeeSchema}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <Pictures {...editControllerProps} {...props} />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>
      {editControllerProps.record && <DeleteButton {...editControllerProps} />}
    </div>
  );
};

/**
 *       <div className="flex row">
        <div className="flex-item">
          <EditForm
            formLabel="Standort"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
            validationSchema={PatchEmployeeSchema}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <EmployeeOfficeInputField {...editControllerProps} {...props} />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>
 */

export default EmployeeEdit;
