// @ts-nocheck
import * as React from 'react';
import { FC } from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { sanitizeFieldRestProps, PublicFieldProps, InjectedFieldProps } from 'react-admin';
import config from '../../config';

const useStyles = makeStyles(
  {
    list: {
      display: 'flex',
      listStyleType: 'none',
    },
    image: {
      margin: '0.5rem',
      maxHeight: '10rem',
    },
  },
  { name: 'RaImageField' }
);

export interface ImageFieldProps extends PublicFieldProps, InjectedFieldProps {
  src?: string;
  sourceType?: string;
  classes?: object;
  record: any;
  className?: string;
  emptyText?: string;
  source?: string;
  isEditMode?: boolean;
  title?: string;
  onPictureTypeChange?: (index: number, pictureType: string) => void;
  index?: number;
}

const EnhancedImageFieldInput: FC<ImageFieldProps> = (props) => {
  const { className, classes: classesOverride, source = 'url', src, title, isEditMode, record = {}, ...rest } = props;
  let sourceValue = get(record, source);
  const classes = useStyles(props);
  sourceValue = record?.rawFile == null ? `${config.URL}${sourceValue}` : sourceValue;

  // @ts-ignore
  const titleValue = get(record, title) || title;
  const onChange = (event) => {
    record[event.target.name] = event.target.value;
  };
  return (
    <>
      <div className={className} {...sanitizeFieldRestProps(rest)}>
        <img title={titleValue} alt={titleValue} src={sourceValue} className={classes.image} />
      </div>
      <div className="details-info">
        <p className="grey">Bildtyp</p>
        <label className={`edit ${props?.isEditMode === true ? 'active' : ''}`}>
          <input
            type="text"
            disabled={props.isEditMode == null || props.isEditMode === false}
            name="pictureType"
            onChange={onChange}
            defaultValue={record.pictureType}
            placeholder="Bild Typ"
            required
            minLength={2}
          />
        </label>
        <p className="grey">Titel</p>
        <label className={`edit ${props?.isEditMode === true ? 'active' : ''}`}>
          <input
            type="text"
            disabled={props.isEditMode == null || props.isEditMode === false}
            name="title"
            onChange={onChange}
            defaultValue={record.title}
            placeholder="Titel"
          />
        </label>
        <p className="grey">Bilddateiname</p>
        <label className={`edit ${props?.isEditMode === true ? 'active' : ''}`}>
          <input
            type="text"
            disabled={props.isEditMode == null || props.isEditMode === false}
            name="pictureName"
            onChange={onChange}
            defaultValue={record.pictureName}
            placeholder="Bild Name"
          />
        </label>
        <p className="grey">Bild Alttext</p>
        <label className={`edit ${props?.isEditMode === true ? 'active' : ''}`}>
          <input
            type="text"
            disabled={props.isEditMode == null || props.isEditMode === false}
            name="pictureAltText"
            onChange={onChange}
            defaultValue={record.pictureAltText}
            placeholder="Bild Alt Text"
          />
        </label>

        {record?.rawFile == null ? (
          <>
            <p className="grey">webp vorhanden:</p>
            <label className="edit">
              {record?.webp != null && <i className="icon icon-check verified"></i>}
              {record?.webp == null && <i className="icon icon-clear notVerified"></i>}
            </label>
          </>
        ) : null}
        {record?.size ? (
          <>
            <p className="grey">Größe in KiB:</p>
            <label className="edit">{!Number.isNaN(record.size) ? `${(record.size / 1024).toFixed(2)} KiB` : ''}</label>
          </>
        ) : null}
      </div>
    </>
  );
};

// What? TypeScript loses the displayName if we don't set it explicitly
EnhancedImageFieldInput.displayName = 'EnhancedImageFieldInput';

export default EnhancedImageFieldInput;
