// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import get from 'lodash/get';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { convertBusinessHours, regex } from '../../utils/businessHoursConverter';

const BusinessHoursInputField = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [convertedBH, setConvertedBH] = useState('');
  const { source, label, record, additionalLabel = false, isEditMode } = props;
  const businessHours = get(record, source);

  useEffect(() => {
    let str = '';
    if (!regex.test(businessHours)) {
      const bhc = convertBusinessHours(businessHours);
      str = bhc.reduce(
        (prev, curr) => (prev ? prev.concat(`\n${curr.day} ${curr.reachability.join(' ')}`) : `${curr.day} ${curr.reachability.join(' ')}`),
        ''
      );
    } else {
      str = '';
    }
    setConvertedBH(str);
  }, [businessHours]);

  const reg =
    /^((?!((?:mo|di|mi|do|fr|sa|so)(?:-di|-mi|-do|-fr|-sa|-so)?=(?:[01]?[0-9]|2[0-4])(?::[0-5][0-9])?-(?:[01]?[0-9]|2[0-4])(?::[0-5][0-9])?(?:;(?:[01]?[0-9]|2[0-4])(?::[0-5][0-9])?-(?:[01]?[0-9]|2[0-4])(?::[0-5][0-9])?)?)$).*)/m;
  const valid = (value) => (reg.test(value) ? 'Fehlerhaft' : undefined);

  const { input, meta } = useField(source, {
    validate: valid,
    defaultValue: 'mo-fr=8-12;15-18',
  });

  return (
    <>
      <Dialog
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        onBackdropClick={() => setShowTooltip(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Öffnungszeiten Notation</DialogTitle>
        <DialogContent>
          <p>
            Die Notation hat folgende Grundlegende Form <br />
            <i>VON_TAG</i>
            <i>-BIS_TAG</i>=VON-BIS;VON-BIS
            <br />
            <i>mo</i>
            <i>-fr</i>=8-12;15-18
            <br />
            Heißt Jeden Tag von Montag bis Freitag ist geöffnet von 8 bis 12 Uhr und von 15 bis 18 Uhr
            <br />
            <br />
            <i>-BIS_TAG</i> ist optional, d.h. folgendes wäre auch möglich <br />
            <i>VON_TAG</i>=VON-BIS;VON-BIS <br />
            <i>mo</i>=8-12;15-18 <br />
            Heißt Montags ist geöffnet von 8 bis 12 Uhr und von 15 bis 18 Uhr
            <br />
            <br />
            Die Notation ünterstützt auch die Eingabe von mehreren Eingaben <br />
            mo=8-12;15-18
            <br />
            di=8-12
            <br />
            mi-fr=9-12;15-18
            <br />
            Heißt Montags von 8-12 und von 15 bis 18, Dienstags von 8 bis 12, und Mittwochs bis Freitags von 9 bis 12 und von 15 bis 18
          </p>
        </DialogContent>
      </Dialog>
      {isEditMode && (
        <>
          <InfoIcon color="primary" style={{ alignSelf: 'center', marginLeft: 5, marginRight: 5 }} onClick={() => setShowTooltip(true)} />
          {additionalLabel && <p className="grey">{label}:</p>}
          <textarea
            disabled={!props.isEditMode}
            {...input}
            className="textarea"
            placeholder={label}
            style={{ borderColor: meta.valid ? 'green' : 'red' }}
          />
          {(!meta.valid || meta.error || meta.submitError) && <span className="error">{meta.error || meta.submitError}</span>}
        </>
      )}
      {!isEditMode && (
        <>
          <InfoIcon color="primary" style={{ alignSelf: 'center', marginLeft: 5, marginRight: 5 }} onClick={() => setShowTooltip(true)} />
          {additionalLabel && <p className="grey">{label}:</p>}
          <textarea disabled={!props.isEditMode} {...input} value={convertedBH} className="textarea" placeholder={label} />
          {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
        </>
      )}
    </>
  );
};

export default BusinessHoursInputField;
