import React from 'react';
import { Field } from 'react-final-form';

const TextAreaInputField = (props) => {
  const { source, label, additionalLabel = false } = props;
  return (
    <Field name={source}>
      {({ input, meta }) => (
        <>
          {additionalLabel && <p className="grey">{label}:</p>}
          <textarea {...input} className="textarea" placeholder={label} disabled={!props.isEditMode} />
          {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
        </>
      )}
    </Field>
  );
};

export default TextAreaInputField;
