import { ResourceProps } from 'react-admin';
import OfficeCreate from './OfficeCreate';
import OfficeEdit from './OfficeEdit';
import OfficeList from './OfficeList';

const Office: ResourceProps = {
  name: 'offices',
  options: { label: 'Standorte' },
  list: OfficeList,
  create: OfficeCreate,
  show: OfficeEdit,
};

export default Office;
