/**
 *
 * Beispiel
 * mo=8:00-12:00;15:00-18:00
 */

/**
 * regex validierung ideen
 *
 * string in einzelne gruppen unterteilen, am besten am "\n"
 * jeder string innerhalb der gruppe muss mit dem kürzel eines tages anfangen (sprich mo, di, mi etc), optional ein bindestrich und ein weiterer tag
 * darauffolgend muss ein = kommen ...
 */

const dayMappings = {
  mo: {
    day: 'Montag',
    position: 0,
  },
  di: {
    day: 'Dienstag',
    position: 1,
  },
  mi: {
    day: 'Mittwoch',
    position: 2,
  },
  do: {
    day: 'Donnerstag',
    position: 3,
  },
  fr: {
    day: 'Freitag',
    position: 4,
  },
  sa: {
    day: 'Samstag',
    position: 5,
  },
  so: {
    day: 'Sonntag',
    position: 6,
  },
};

export const regex =
  /^(?<fromDay>mo|di|mi|do|fr|sa|so)(?<toDay>-mo|-di|-mi|-do|-fr|-sa|-so)?=(?<reach>\d{1,2}:\d{2}-\d{1,2}:\d{2}(;(\d{1,2}:\d{2}-\d{1,2}:\d{2})+)?)/gm;

const days = ['mo', 'di', 'mi', 'do', 'fr', 'sa', 'so'];

/**
 *
 * @param {String} reachabilityString
 */
export function convertBusinessHours(reachabilityString: string) {
  // split string at \n
  const breakSplit = reachabilityString && typeof reachabilityString === 'string' ? reachabilityString.split('\n') : [];
  // for every string inside breakSplit we have to check if its for multiple days ...
  const reachability: { day: string; reachability: string[] }[] = [];
  breakSplit.forEach((str) => {
    // split on =
    const split = str.split('=');
    if (split.length !== 2) throw new Error('Invalid String found');
    if (split[0].includes('-')) {
      // it has a range of days
      const dayRange = split[0].split('-');
      if (dayRange.length !== 2) throw new Error('Invalid day range');
      const fromMapping = dayMappings[dayRange[0]];
      const toMapping = dayMappings[dayRange[1]];
      // const daysRange = [...days].slice(fromMapping.position, toMapping.position +1);
      const daysRange = days.slice(fromMapping.position, toMapping.position + 1);
      daysRange.forEach((d) => {
        reachability.push({
          day: dayMappings[d].day,
          reachability: split[1].split(';'),
        });
      });
    } else {
      // it doesnt have a range of days
      // get day object from mapping
      const mapping = dayMappings[split[0]];
      if (mapping == null) throw new Error('Could not find mapping');
      // convert reachability ...
      const dayReach = split[1].split(';');
      reachability.push({ day: mapping.day, reachability: dayReach });
    }
  });

  return reachability;
}
