import config from './config';
import { AuthProvider, fetchUtils, HttpError } from 'react-admin';

interface Header {
  headers?: Headers;
  allowGetBody?: boolean;
}

const authProvider: AuthProvider = {
  login: ({ email, password }: { email: string; password: string }) => {
    const request = new Request(config.API_URL + '/users/login', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ status, data }) => {
        if (status === 'ok' && data.token) localStorage.setItem(config.AUTH_TOKEN_KEY!, data.token);
      });
  },
  logout: async () => {
    return new Promise(async (resolve, reject) => {
      const token = localStorage.getItem(config.AUTH_TOKEN_KEY!);
      if (!token) return resolve();
      const response = await fetch(config.API_URL + '/users/logout', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }),
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const json = await response.json();
      if (json.status === 'ok') {
        localStorage.removeItem(config.AUTH_TOKEN_KEY!);
        return resolve();
      } else {
        reject();
        throw new Error('Logout not OK!');
      }
    });
  },
  checkAuth: () => {
    return new Promise((resolve, reject) => {
      return localStorage.getItem(config.AUTH_TOKEN_KEY!) ? resolve() : reject();
    });
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403 || error instanceof HttpError) {
      localStorage.removeItem(config.AUTH_TOKEN_KEY!);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const token = localStorage.getItem(config.AUTH_TOKEN_KEY!);
    if (!token) return Promise.reject();
    const jwtParts = token.split('.');
    if (!jwtParts || jwtParts.length !== 3) return Promise.reject();
    const payload = JSON.parse(atob(jwtParts[1]));
    if (!payload || !payload.scope) return Promise.reject();
    return Promise.resolve(payload.scope.split(' '));
  },
  getHttpClient: () => {
    return (url: string, options: Header = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }
      const token = localStorage.getItem(config.AUTH_TOKEN_KEY!);
      if (token) options.headers.set('Authorization', `Bearer ${token}`);
      options.allowGetBody = true;
      return fetchUtils.fetchJson(url, options).then(({ headers, json }) => {
        const newToken = headers.get('x-token');
        if (newToken) localStorage.setItem(config.AUTH_TOKEN_KEY!, newToken);
        return { headers, json };
      });
    };
  },
};

export default authProvider;
