import { ResourceProps } from 'react-admin';
import PictureEdit from './PictureEdit';
import PictureList from './PictureList';

const Picture: ResourceProps = {
  name: 'pictures',
  options: { label: 'Bilder' },
  list: PictureList,
  show: PictureEdit
};

export default Picture;
