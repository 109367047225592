import React from 'react';
import { useDataProvider, useEditController, useNotify, useRefresh } from 'react-admin';
import CreateFrom from '../../Components/Form/CreateForm';
import TextInputField from '../../Components/Form/TextInputField';
import SubmitButton from '../../Components/Form/SubmitButton';

const generateAPITokenText = (record) => {
  const result = `${record.currentApiToken.token}`;

  return result;
};

const WebsitesLineEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const editProps = useEditController({
    ...props,
    mutationMode: 'pessimistic',
    onFailure: () => {
      notify('Da lief etwas falsch :(', 'error');
    },
    onSuccess: () => {
      notify('Erfolgreich gespeichert!', 'success');
      refresh();
    },
  });
  const { record } = props;
  const dataProvider = useDataProvider();

  const clearCache = (formProps: any) => {
    // @ts-ignore
    const url = document.getElementById(record.id).value;
    dataProvider
      .clearCache(record.id, url)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
  };
  return (
    <td colSpan={9}>
      <CreateFrom {...editProps}>
        {(formProps) => {
          return (
            <div className="flex row">
              <div className="headline">
                <h2>Webseiten-Details ansehen/bearbeiten</h2>
                <hr className="line" />
              </div>
              <div className="flex-item-33">
                <TextInputField source="domain" label="Domain" {...formProps} disabled />
              </div>
              <div className="flex-item-33"></div>
              <div className="interactions">
                <div className="flex">
                  <h4>API Key</h4>
                  <textarea className="small" spellCheck="false" readOnly value={generateAPITokenText(record)} />
                </div>
              </div>
              <div className="interactions">
                <SubmitButton buttonClassName="btn" {...formProps} />
              </div>
            </div>
          );
        }}
      </CreateFrom>
      <div className="flex row">
        <div className="headline">
          <h2>Webseiten-Details ansehen/bearbeiten</h2>
          <hr className="line" />
        </div>
        <div className="flex-item-33">
          <>
            <p className="grey">Domäne:</p>
            <label className={'edit active'}>
              <input id={record.id} />
            </label>
            <input onClick={clearCache} type="button" className={'btn btn-blue btn-no-margin'} value="Cache leeren" />
          </>
        </div>
      </div>
    </td>
  );
};

export default WebsitesLineEdit;
