import { ResourceProps } from 'react-admin';
import EmployeeCreate from './EmployeeCreate';
import EmployeeEdit from './EmployeeEdit';
import EmployeeList from './EmployeeList';

const Employee: ResourceProps = {
  name: 'employees',
  options: { label: 'Personen' },
  list: EmployeeList,
  show: EmployeeEdit,
  create: EmployeeCreate,
};

export default Employee;
