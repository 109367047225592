// @ts-nocheck
import React, { useCallback, useState } from 'react';
import { ResourceProps, useDataProvider, useNotify, useRefresh } from 'ra-core';
import { List, DataGrid, TextField } from '../../Components/List';
import { useHistory } from 'react-router-dom';
import BooleanField from '../../Components/List/BooleanField';
import EnhancedImageField from '../../Components/List/EnhancedImageField';
import CompressPictureDialog from '../../Components/CompressPictureDialog';

const isCompressedTransform = (value: any) => {
  if (value != null && Array.isArray(value) && value.length > 0) {
    return true;
  }
  return false;
};

interface Props {
  rowMode: 'show' | 'toggleSelection';
  setRowMode: (rowMode: 'show' | 'toggleSelection') => void;
}

const BulkCompressMode = ({ rowMode, setRowMode }: Props) => {
  return (
    <button
      onClick={() =>
        setRowMode(rowMode === 'show' ? 'toggleSelection' : 'show')
      }
      className="btn btn-border"
    >
      Bilder {rowMode === 'show' ? 'auswählen starten' : 'auswählen abbrechen'}
    </button>
  );
};

const PictureList = (props: ResourceProps) => {
  const [rowMode, setRowMode] = useState<'show' | 'toggleSelection'>('show');
  const [listIds, setListIds] = useState([]);
  const { basePath } = props;
  const history = useHistory();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleAddNew = useCallback(() => {
    if (!history) return;
    history.push(`${basePath}/create`);
  }, [history, basePath]);

  const onSelect = useCallback(
    (ids: Identifier[]) => {
      console.log('onSelect');
      const values = [...listIds, ids];
      setListIds(values);
    },
    [listIds]
  );

  const onToggleItem = useCallback(
    (id: Identifier) => {
      if (listIds.includes(id)) {
        console.log('includes');
        setListIds([...listIds.filter((lid) => lid !== id)]);
      } else {
        console.log('does notincludes');
        setListIds([...listIds, id]);
      }
    },
    [listIds]
  );

  const compressPictures = (algorithm: any) => {
    const ids = [...listIds];
    console.log(algorithm);
    return dataProvider
      .compressPictures('pictures', {
        ids,
        ...algorithm,
      })
      .then((response) => {
        console.log(response);
        notify('Bild erfolgreich komprimiert', 'success');
        refresh();
        return true;
      })
      .catch((error) => {
        console.log(error);
        notify('Es ist ein Fehler aufgetreten', 'error');
        return false;
      });
  };

  return (
    <>
      <BulkCompressMode rowMode={rowMode} setRowMode={setRowMode} />
      <List
        {...props}
        handleAdd={handleAddNew}
        bulkActionButtons={
          rowMode !== 'show' ? (
            <CompressPictureDialog
              btnLabel="Bilder komprimieren"
              btnTitle="Bilder komprimieren"
              compressPicture={compressPictures}
            />
          ) : undefined
        }
      >
        <DataGrid
          rowClick={rowMode}
          selectedIds={listIds}
          onSelect={onSelect}
          onToggleItem={onToggleItem}
        >
          <BooleanField
            notSearchable
            source="compressedPictures"
            label="Komprimierte Bilder"
            transform={isCompressedTransform}
          />
          <TextField source="pictureType" label="Typ" />
          <EnhancedImageField
            notSearchable
            source="url"
            label="URL"
            isList={true}
          />
          <TextField
            source="size"
            label="Größe in KibiBytes"
            format={(value) => {
              if (value && !Number.isNaN(Number(value))) {
                return `${(value / 1024).toFixed(2)} KiB`;
              }
              return `${value} KiB`;
            }}
          />
        </DataGrid>
      </List>
    </>
  );
};

export default PictureList;
