import React, { useState, useCallback, isValidElement, useEffect } from 'react';
import { Form } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import { transformInstancePath } from '../../utils/instancePath';
import Ajv, { ValidateFunction } from 'ajv';
import addFormats from 'ajv-formats';
import localize_de from 'ajv-i18n/localize/de';
import { EditControllerProps, Record } from 'react-admin';
import arrayMutators from 'final-form-arrays';

const ajv = new Ajv({ allErrors: true, coerceTypes: true });
addFormats(ajv);

export interface EditFormProps {
  validationSchema?: object;
  boxClassName: string;
  formLabel: string;
  noEditMode: boolean;
  overlay?: React.ReactElement;
}

export interface EditFormChildrenProps {
  isEditMode: boolean;
  record: Record;
  saving: boolean;
  editModeCallback: () => void;
  saveCallback: (values: Partial<Record>) => void;
}

type Props = EditFormProps & EditControllerProps;

const EditForm: React.FC<Props> = (props) => {
  const { boxClassName, formLabel, noEditMode, validationSchema, children, overlay, record, save, saving, redirect = 'edit' } = props;
  const [isEditMode, setIsEditMode] = useState(noEditMode ? true : false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOverlayActive, setIsOverlayActive] = useState(false);
  const [compiledSchema, setCompiledSchema] = useState<ValidateFunction | undefined>(undefined);
  const editModeCallback = useCallback(() => {
    setIsEditMode(!isEditMode);
  }, [isEditMode]);
  const saveCallback = useCallback(
    (values) => {
      if (saving) return;
      setIsUpdating(true);
      save({ ...values }, redirect);
    },
    [save, saving, redirect]
  );
  useEffect(() => {
    if (isUpdating && !saving) {
      !noEditMode && setIsEditMode(false);
      setIsUpdating(false);
    }
  }, [saving, setIsEditMode, isUpdating, setIsUpdating, noEditMode]);
  const showOverlayCallback = useCallback(() => {
    setIsOverlayActive(true);
  }, [setIsOverlayActive]);
  useEffect(() => {
    if (!validationSchema) return;
    const cS = ajv.compile(validationSchema);
    setCompiledSchema(() => cS);
  }, [validationSchema, setCompiledSchema]);
  const validationCallback = useCallback(
    (values) => {
      const errors = {};
      if (compiledSchema == null || typeof compiledSchema !== "function") return errors;
      const valid = compiledSchema(values);
      if (valid || !compiledSchema.errors) return errors;
      // @ts-ignore
      localize_de(compiledSchema.errors);
      compiledSchema.errors.forEach((err) => {
        if (err.instancePath === '/pictures/0/pictureType') {
          const path = transformInstancePath(err.instancePath, '');
          // const path = "pictures[0].url.pictureType";
          set(errors, path, 'muss das erforderliche Attribut phoneNumber enthalten');
        } else {
          if (err.keyword === 'required') {
            const path = transformInstancePath(err.instancePath, err.params.missingProperty);
            set(errors, path, err.message);
          } else {
            const path = transformInstancePath(err.instancePath, '');
            set(errors, path, err.message);
          }
        }
      });
      return errors;
    },
    [compiledSchema]
  );
  return (
    <div className={'shadow-box ' + boxClassName}>
      <h3 className="underlined">{formLabel}</h3>
      {(!noEditMode || overlay) && (
        <i className={'icon ' + (overlay ? 'icon-history' : 'icon-pencil')} onClick={overlay ? showOverlayCallback : editModeCallback}></i>
      )}
      <Form
        onSubmit={saveCallback}
        initialValues={record ?? {}}
        initialValuesEqual={isEqual}
        keepDirtyOnReinitialize
        mutators={{ ...arrayMutators }}
        validate={validationSchema ? validationCallback : undefined}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="details-info">
                {typeof children === 'function' && children({ isEditMode, record, saving, editModeCallback, saveCallback })}
              </div>
              {overlay &&
                isValidElement(overlay) &&
                React.cloneElement(overlay, {
                  // @ts-ignore
                  record,
                  isOverlayActive,
                  setIsOverlayActive,
                })}
            </form>
          );
        }}
      />
    </div>
  );
};

export default EditForm;

/**
 * {React.Children.map(children, (field) =>
                                    isValidElement(field) ? React.cloneElement<EditFormChildrenProps>(field, {
                                        isEditMode,
                                        record,
                                        saving,
                                        editModeCallback,
                                        saveCallback
                                    }) : null
                                )}
 */
