import React, { useRef, useState } from 'react';
import { useField } from 'react-final-form';
import { InputProps } from 'ra-core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';

import '../../styles/custom.css';
import '../../styles/uikit.min.css';
import { ReactComponent as GermanyMap } from '../../img/germany-map.svg';
import { ReactComponent as Pin } from '../../img/pin.svg';

interface Props {}

type TextInputFieldProps = Props & InputProps & (CreateFormChildrenProps | EditFormChildrenProps);

const MapInput = (props: TextInputFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pin, setPin] = useState<null | { left: string; top: string }>({ left: '37%', top: '2%' });
  const ref = useRef<SVGElement>();

  const { input, meta } = useField('address.mapCoords');
  const { onChange } = input;

  const onDown = (event: React.MouseEvent<SVGElement>) => {
    // @ts-ignore
    const { layerX, layerY } = event.nativeEvent;
    if (ref.current) {
      const { clientHeight, clientWidth } = ref.current;
      // abziehen der höhe breite des pins
      const left = (((layerX - 6) / clientWidth) * 100).toFixed();
      const top = (((layerY - 6) / clientHeight) * 100).toFixed();

      setPin({
        left: `${left}%`,
        top: `${top}%`,
      });
      onChange(`left: ${left}%; top: ${top}%`);
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onBackdropClick={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Standort auswählen</DialogTitle>
        <DialogContent>
          <div id="germanymap" style={{ width: 600, height: 800, position: 'relative' }}>
            <GermanyMap
              /* @ts-ignore */
              ref={ref}
              className="uk-preserve germany-map uk-width-1-1"
              onMouseDown={onDown}
            ></GermanyMap>
            {pin?.left && pin.top ? (
              <div
                className="uk-position-absolute uk-transform-center uk-text-success pin fall"
                style={{ left: pin?.left, top: pin?.top }}
              >
                <Pin className="uk-icon" />
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>

      {props.isEditMode === false ? (
        <p className="grey">{props.label}</p>
      ) : (
        <p className="grey">
          <button
            type="button"
            title="open"
            className="btn btn-blue"
            onClick={() => setIsOpen(!isOpen)}
            style={{ margin: '0' }}
          >
            {props.label}
          </button>
        </p>
      )}

      <label className={'edit' + (props.isEditMode ? ' active' : '')}>
        <input {...input} />
        {(meta.error || meta.submitError) && meta.touched && (
          <span className="error">{meta.error || meta.submitError}</span>
        )}
      </label>
    </>
  );
};

export default MapInput;
