import React, { useState } from 'react';
import { EditControllerProps, Identifier, useDataProvider, useNotify, useRefresh } from 'ra-core';
import { useEditController } from 'react-admin';
import EditForm, { EditFormChildrenProps } from '../../Components/Form/EditForm';
import SubmitButton from '../../Components/Form/SubmitButton';
import EnhancedImageFieldInput from '../../Components/Form/EnhancedImageFieldInput';
import Pictures from '../../Components/Form/Pictures';
import CompressPictureDialog from '../../Components/CompressPictureDialog';
import CompareImage from '../../Components/CompareImage';
import RemoveCompressedPictureDialog from '../../Components/RemoveCompressedPictureDialog';

const PictureEdit = (props: EditControllerProps) => {
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [toBeDeletedFile, setToBeDeletedFile] = useState<any>(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const editControllerProps = useEditController({
    ...props,
    mutationMode: 'pessimistic',
    onFailure: () => {
      notify('Da lief etwas falsch :(', 'error');
      refresh();
    },
    onSuccess: () => {
      notify('Erfolgreich gespeichert!', 'success');
      refresh();
    },
  });

  const compressPicture = (params: any) => {
    return dataProvider
      .compressPicture('pictures', {
        id: editControllerProps?.record?.id,
        params,
      })
      .then((response) => {
        notify('Bilder erfolgreich komprimiert', 'success');
        refresh();
        return true;
      })
      .catch((error) => {
        notify('Es ist ein Fehler aufgetreten', 'error');
        return false;
      });
  };

  const onRemove = (file: any) => {
    setToBeDeletedFile(file);
    setDeleteDialogIsOpen(true);
  };

  const onDelete = (id: Identifier) => {
    dataProvider
      .delete('pictures', {
        id,
        previousData: toBeDeletedFile,
      })
      .then((response) => {
        if (response.data?.status !== 'ok') {
          setDeleteDialogIsOpen(false);
          notify('Es ist ein Fehler aufgetreten', 'error');
          refresh();
        } else {
          setDeleteDialogIsOpen(false);
          notify('Erfolgreich gelöscht', 'success');
          refresh();
        }
      })
      .catch((err) => {
        setDeleteDialogIsOpen(false);
        notify('Es ist ein Fehler aufgetreten', 'error');
        refresh();
      });
  };

  return (
    <div className={'sale details'}>
      <RemoveCompressedPictureDialog
        onDelete={onDelete}
        isOpen={deleteDialogIsOpen}
        setIsOpen={setDeleteDialogIsOpen}
        file={toBeDeletedFile}
      />
      <div className="flex space">
        <h2 className="symbol">Bild</h2>
        <CompressPictureDialog compressPicture={compressPicture} />
        <CompareImage record={editControllerProps.record} />
      </div>
      <div className="flex row">
        <div className="flex-item">
          <EditForm formLabel="Original Bild" boxClassName="overview" {...editControllerProps} noEditMode={false}>
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <EnhancedImageFieldInput {...props} source="url" label="URL" />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
        <div className="flex-item">
          <EditForm formLabel="Komprimierte Bilder" boxClassName="overview" {...editControllerProps} noEditMode={false}>
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <Pictures {...editControllerProps} {...props} onRemove={onRemove} disableDropzone={true} source="compressedPictures" />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>

      {/* {editControllerProps.record && <DeleteButton {...editControllerProps} />} */}
    </div>
  );
};

/** 
 *         <div className="flex-item">
          <EditForm
            formLabel="Bilder"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
            validationSchema={PatchEmployeeSchema}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <Pictures {...editControllerProps} {...props} />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
 */

/** 
 *       <div className="flex row">
        <div className="flex-item">
          <EditForm
            formLabel="Standort"
            boxClassName="overview"
            {...editControllerProps}
            noEditMode={false}
            validationSchema={PatchEmployeeSchema}
          >
            {(props: EditFormChildrenProps) => {
              return (
                <>
                  <EmployeeOfficeInputField
                    {...editControllerProps}
                    {...props}
                  />
                  <SubmitButton {...props} isLeft={true} />
                </>
              );
            }}
          </EditForm>
        </div>
      </div>
 */

export default PictureEdit;
