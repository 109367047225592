import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import localize_de from 'ajv-i18n/localize/de';
import { CreateControllerProps, Record as RARecord } from 'react-admin';
import { transformInstancePath } from '../../utils/instancePath';
import arrayMutators from 'final-form-arrays';

const ajv = new Ajv({ allErrors: true, coerceTypes: true });
addFormats(ajv);

interface Props {
  // validationSchema?: JSONSchemaType<SchemaObject>;
  validationSchema?: any;
}

export type CreateFormProps = Props & CreateControllerProps;

export interface CreateFormChildrenProps {
  record: Partial<RARecord> | undefined;
  isEditMode: boolean;
  saving: boolean;
}

const CreateForm: React.FC<CreateFormProps> = (props) => {
  const { validationSchema, children, record, save, saving, redirect = 'edit' } = props;
  const [isUpdating, setIsUpdating] = useState(false);
  const [compiledSchema, setCompiledSchema] = useState<any>(undefined);
  const saveCallback = useCallback(
    (values) => {
      if (saving) return;
      setIsUpdating(true);
      save({ ...values }, redirect);
    },
    [save, saving, redirect]
  );
  useEffect(() => {
    if (isUpdating && !saving) {
      setIsUpdating(false);
    }
  }, [saving, isUpdating, setIsUpdating]);
  useEffect(() => {
    if (!validationSchema) return;
    const cS = ajv.compile(validationSchema);
    setCompiledSchema(() => cS);
  }, [validationSchema, setCompiledSchema]);
  const validationCallback = useCallback(
    (values) => {
      const errors = {};
      if (!compiledSchema) return errors;
      const valid = compiledSchema(values);
      if (valid || !compiledSchema.errors) return errors;
      // @ts-ignore
      localize_de(compiledSchema.errors);
      compiledSchema.errors.forEach((err) => {
        console.log(err);
        if (err.instancePath === '/pictures/0/pictureType') {
          const path = transformInstancePath(err.instancePath, '');
          // const path = "pictures[0].url.pictureType";
          set(errors, path, 'muss das erforderliche Attribut phoneNumber enthalten');
        } else {
          if (err.keyword === 'required') {
            const path = transformInstancePath(err.instancePath, err.params.missingProperty);
            set(errors, path, err.message);
          } else {
            const path = transformInstancePath(err.instancePath, '');
            set(errors, path, err.message);
          }
        }
      });
      return errors;
    },
    [compiledSchema]
  );
  return (
    <Form
      onSubmit={saveCallback}
      initialValues={record ?? {}}
      initialValuesEqual={isEqual}
      keepDirtyOnReinitialize
      validate={validationSchema ? validationCallback : undefined}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            {typeof children === 'function' &&
              children({
                isEditMode: true,
                record,
                saving,
              })}
          </form>
        );
      }}
    />
  );
};

export default CreateForm;
