import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { Checkbox } from '@material-ui/core';
import { get } from 'lodash';
import { InputProps } from 'react-admin';
import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';

interface Props {
  label: string;
}

type BooleanInputFieldProps = Props & InputProps & (CreateFormChildrenProps | EditFormChildrenProps);

const BooleanInputField = (props: BooleanInputFieldProps) => {
  const { record, source, label, isEditMode } = props;
  return (
    <Field name={source}>
      {({ input }) => {
        const bool = get(record, source);
        const { onChange } = input;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [state, setState] = useState(bool ?? false);
        const onToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
          // @ts-ignore
          if (event.target?.id === `checkbox_${source}`) {
            setState(!state);
          } else {
            event.stopPropagation();
          }
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          onChange(state);
        }, [onChange, state]);

        return (
          <>
            {isEditMode && (
              <>
                <p className="grey">{label}:</p>
                <label className="edit">
                  <Checkbox id={`checkbox_${source}`} color="primary" className={`select-item`} checked={state} onClick={onToggle} />
                </label>
              </>
            )}
            {isEditMode === false && (
              <>
                <p className="grey">{label}:</p>
                <label className="edit">
                  {state === true && <i className="icon icon-check verified"></i>}
                  {state === false && <i className="icon icon-clear notVerified"></i>}
                </label>
              </>
            )}
          </>
        );
      }}
    </Field>
  );
};

export default BooleanInputField;
