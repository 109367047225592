import { DataProvider, fetchUtils, GetListParams, GetManyParams, GetOneParams, UpdateParams } from 'react-admin';
import { stringify } from 'query-string';
import { convertFileToBase64 } from './utils/convertFileToBase64';

interface Picture {
  rawFile: File;
  pictureType: string;
  pictureName: string;
  title: string;
  pictureAltText: string;
}

const dataProvider = (apiURL: string, httpClient = fetchUtils.fetchJson): DataProvider => ({
  getList: (resource: string, params: GetListParams) => {
    console.log('getList: ', { resource, params });
    return new Promise(async (resolve, reject) => {
      let modResource = '' + resource;
      let modFilter = { ...params.filter };
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      console.log(params.filter);
      const query = {
        filter: encodeURI(JSON.stringify({ ...fetchUtils.flattenObject(modFilter) })),
        sort: field,
        order: order,
        offset: (page - 1) * perPage,
        limit: perPage,
      };
      console.log('query: ', query);
      const url = `${apiURL}/${modResource}?${stringify(query)}`;
      try {
        const response = await httpClient(url);
        if (response.json && response.json.status === 'ok') {
          const { json } = response;
          const dataKey = Object.keys(json.data).filter((e) => e !== 'total')[0];
          console.log(json.data[dataKey]);
          return resolve({
            data: json.data[dataKey],
            total: parseInt(json.data.total, 10),
          });
        } else {
          return reject(response);
        }
      } catch (error) {
        console.log(error);
        return reject(error);
      }
    });
  },
  getOne: async (resource: string, params: GetOneParams) => {
    console.log('getOne');
    return new Promise(async (resolve, reject) => {
      let modResource = '' + resource;
      const filterOptions = modResource.split('_');
      if (filterOptions.length === 3) modResource = filterOptions[0];
      if (filterOptions && filterOptions[1] && filterOptions[1] === 'credits') {
        modResource = `${resource}`.split('_').join('/');
      }
      const response = await httpClient(`${apiURL}/${modResource}/${params.id}`);
      if (response?.json?.status === 'ok') {
        console.log('getOne data: ', response.json.data);
        return resolve({ data: response.json.data });
      } else {
        return reject(response ?? 'Error');
      }
    });
  },
  create: async (resource, params) => {
    console.log('create');
    return new Promise(async (resolve, reject) => {
      let modResource = '' + resource;
      const filterOptions = modResource.split('_');
      if (filterOptions.length === 3) modResource = filterOptions[0];
      const data = Object.assign({}, params.data);
      console.log('params: ', params);

      if (data?.pictures && Array.isArray(data.pictures)) {
        console.log(data.pictures);
        const pictures = (data.pictures as Picture[]).map((picture: Picture) =>
          convertFileToBase64(picture.rawFile, {
            pictureType: picture.pictureType,
            name: picture.rawFile.name,
            pictureName: picture.pictureName,
            title: picture.title,
            pictureAltText: picture.pictureAltText,
          })
        );

        data.pictures.forEach((picture) => {
          // pictures.push(convertFileToBase64(picture.rawFile, { type: picture.type, name: picture.rawFile.name }));
        });
        const x = await Promise.all(pictures);
        data.pictures = [...x];
      }

      // da wir nur eine Stadt auswählen, setzen wir die id direkt und schicken den "rest" nicht mit
      if (data?.address?.city?.id) {
        data.address.city = data.address.city.id;
      }

      if(data?.city?.id) {
        data.city = data.city.id;
      }

      // da wir nur einen manager auswählen, setzen wir die id direkt
      if (data?.manager?.id) {
        data.manager = data.manager.id;
      }

      if (data?.picture) {
        console.log(data.picture);
        const picture = await convertFileToBase64(data.picture.rawFile);
        data.picture = {
          image: picture,
          type: data.picture.rawFile.type,
          name: data.picture.rawFile.name,
        };
      }

      if (data?.employees && Array.isArray(data.employees)) {
        data.employees = data.employees.reduce((acc, curr) => (acc ? [...acc, curr.id] : acc), []);
      }

      if (data?.managers && Array.isArray(data.managers)) {
        data.managers = data.managers.reduce((acc, curr) => (acc ? [...acc, curr.id] : acc), []);
      }

      if (data?.products && Array.isArray(data.products)) {
        data.products = (data.products as []).filter((val) => val != null);
      }

      if (data?.responsibleArea) {
        data.responsibleArea = Array.isArray(data.responsibleArea) ? data.responsibleArea : data.responsibleArea.split('\n');
      }

      console.log('post: ', data);

      return httpClient(`${apiURL}/${modResource}`, {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response?.json?.status === 'ok') {
            return resolve({ data: response.json.data });
          } else {
            return reject('Error');
          }
        })
        .catch((err) => {
          console.log(err);
          return reject('Error');
        });
    });
  },
  delete: (resource, params) => {
    console.log('delete');
    return new Promise((resolve, reject) => {
      let modResource = '' + resource;
      const filterOptions = modResource.split('_');
      if (filterOptions.length === 3) modResource = filterOptions[0];
      return httpClient(`${apiURL}/${modResource}/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => {
        return resolve({ data: json });
      });
    });
  },
  deleteMany: (resource, params) => {
    console.log('deleteMany');
    return new Promise((resolve, reject) => {
      return reject('');
    });
  },
  getMany: async (resource: string, params: GetManyParams) => {
    console.log('getMany');
    return new Promise(async (resolve, reject) => {
      const query = {
        id: JSON.stringify(params.ids),
      };
      let modResource = '' + resource;
      const filterOptions = modResource.split('_');
      if (filterOptions.length === 3) modResource = filterOptions[0];
      const url = `${apiURL}/${modResource}?${stringify(query)}`;
      console.log({ resource, params, query, filterOptions });
      const response = await httpClient(url);
      if (response?.json?.status === 'ok') {
        const dataKey = Object.keys(response.json.data).filter((e) => e !== 'total')[0];
        return resolve({ data: response.json.data[dataKey] });
      } else {
        return reject(response);
      }
    });
  },
  getManyReference: (resource, params) => {
    console.log('getManyReference');
    console.log({ resource, params });
    return new Promise((resolve, reject) => {
      return reject('');
    });
  },
  update: async (resource: string, params: UpdateParams) => {
    console.log('update');
    let modResource = '' + resource;
    const filterOptions = modResource.split('_');
    if (filterOptions.length === 3) modResource = filterOptions[0];
    const data = Object.assign({}, params.data);
    console.log(data);
    if (data?.pictures && Array.isArray(data.pictures)) {
      const pictures = (data.pictures as Picture[]).map((picture: Picture) =>
        picture.rawFile
          ? convertFileToBase64(picture.rawFile, {
              pictureType: picture.pictureType,
              name: picture.rawFile.name,
              pictureName: picture.pictureName,
              title: picture.title,
              pictureAltText: picture.pictureAltText,
            })
          : { ...picture }
      );
      // @ts-ignore
      const x = await Promise.all(pictures);
      data.pictures = [...x];
    }

    // da wir nur eine Stadt auswählen, setzen wir die id direkt und schicken den "rest" nicht mit
    if (data?.address?.city?.id) {
      data.address.city = data.address.city.id;
    }

    if (data?.city?.id) {
      data.city = data.city.id;
    }

    if (data?.products && Array.isArray(data.products)) {
      data.products = (data.products as []).filter((val) => val != null);
    }

    // da wir nur einen manager auswählen, setzen wir die id direkt
    if (data?.manager?.id) {
      data.manager = data.manager.id;
    }

    if (data?.managers && Array.isArray(data.managers)) {
      data.managers = data.managers.map((manager) => (manager.id ? manager.id : manager._id));
    }

    if (data?.employees && Array.isArray(data.employees)) {
      data.employees = data.employees.reduce((acc, curr) => (acc ? [...acc, curr.id ?? curr._id] : acc), []);
    }

    if (data?.textblocks && Array.isArray(data.textblocks)) {
      data.textblocks = data.textblocks.reduce((acc, curr) => {
        if (curr.id || curr._id) {
          return [...acc, { id: curr.id ?? curr._id, mid: curr.mid, markdown: curr.markdown }];
        } else {
          return [...acc, { mid: curr.mid, markdown: curr.markdown }];
        }
      }, []);
    }

    if (data?.office) {
      data.office = data.office.id;
    }

    if (data?.responsibleArea) {
      data.responsibleArea = Array.isArray(data.responsibleArea) ? data.responsibleArea : data.responsibleArea.split('\n');
    }

    return httpClient(`${apiURL}/${modResource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
      .then(({ json }) => ({ data: json.data }))
      .catch((err) => err);
  },
  updateMany: (resource, params) => {
    console.log('updateMany');
    return new Promise((resolve, reject) => {
      return reject('');
    });
  },
  compressPicture: async (resource, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await httpClient(`${apiURL}/pictures/${params.id}/compress/`, {
          method: 'POST',
          body: JSON.stringify(params.params),
        });
        if (response.json.status === 'error') {
          return reject(response.json.data.error);
        }
        return resolve({ data: response.json.data });
      } catch (error) {
        return reject(error);
      }
    });
  },
  compressPictures: async (resource, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await httpClient(`${apiURL}/pictures/compressPictures/`, {
          method: 'POST',
          body: JSON.stringify(params),
        });
        if (response.json.status === 'error') {
          return reject(response.json.data.error);
        }
        return resolve({ data: response.json.data });
      } catch (error) {
        return reject(error);
      }
    });
  },
  clearCache: async (id: string, url: string) => {
    return new Promise(async (resolve, reject) => {
      const response = await httpClient(`${apiURL}/websites/${id}/clearCache/`, {
        method: 'POST',
        body: JSON.stringify({ url }),
      });
      if (response?.json?.status === 'ok') {
        return resolve({ data: response.json });
      } else {
        return reject(response);
      }
    });
  },
});

export default dataProvider;
