// @ts-nocheck
import React, { useCallback } from 'react';
import { ResourceComponentProps } from 'react-admin';
import { useHistory } from 'react-router-dom';

import { List, DataGrid, TextField } from '../../Components/List';
import { productTypes } from '../../utils/types';

const format = (values?: Array<string>) => {
  if(values && Array.isArray(values)) {
    const t = values.map((val) => productTypes[val] || "not_found");
    return t.join(", ");
  }
  return values;
}

const ProductManagerList = (props: ResourceComponentProps) => {
  const { basePath } = props;
  const history = useHistory();

  const handleAddNew = useCallback(() => {
    if (!history) return;
    history.push(`${basePath}/create`);
  }, [history, basePath]);

  return (
    <List {...props} handleAdd={handleAddNew} perPage={50}>
      <DataGrid rowClick="show">
        <TextField source="manager.firstName" label="Vorname" />
        <TextField source="manager.lastName" label="Nachname" />
        <TextField source="products" label="Produkte" format={format} />
      </DataGrid>
    </List>
  );
};

export default ProductManagerList;
