import React from 'react';
import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';

interface Props {
  buttonClassName?: string;
  label?: string;
  isLeft?: boolean;
}
type SubmitButtonProps = Props & (EditFormChildrenProps | CreateFormChildrenProps);

const SubmitButton = (props: SubmitButtonProps) => {
  const { saving, isEditMode, buttonClassName = 'btn btn-blue', label = 'Speichern', isLeft = false } = props;
  return isLeft ? (
    <div className="padding">
      <input type="submit" className={'btn btn-blue btn-no-margin' + (saving || !isEditMode ? ' hide' : '')} value={label} />
      <div className={'loader' + (saving ? ' show' : '')}>
        <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" className={'loader' + (saving ? ' show' : '')} />
      </div>
    </div>
  ) : (
    <>
      <div className={'loader' + (saving ? ' show' : '')}>
        <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" className={'loader' + (saving ? ' show' : '')} />
      </div>
      <input type="submit" className={buttonClassName + (saving || !isEditMode ? ' hide' : '')} value="Speichern" />
    </>
  );
};

export default SubmitButton;
