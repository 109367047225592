import get from 'lodash/get';
import { InputProps } from 'ra-core';


interface Props {
  replace: {
    [key: string]: string;
  };
  label: string;
};

const EnumReplaceField = ({record, source, replace}: Props & InputProps) => {
  const value = get(record, source);
  const replacedValue = replace[value];
  return replacedValue;
}

export default EnumReplaceField;