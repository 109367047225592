// @ts-nocheck
import React from "react";
import { NavLink } from 'react-router-dom';

const MenuItemLink = (props: any) => {
    return (
        <li>
            <NavLink
                activeClassName="active"
                to={props.to}
                key={props.name}
                {...props}
            >
                {props.text}
            </NavLink>
        </li>
    )
}

export default MenuItemLink;