import { Record } from 'ra-core';
import ReactCompareImage from 'react-compare-image';
import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import config from '../config';

interface Props {
  record?: Record;
}

type CompareImages = {
  leftImage?: string;
  rightImage?: string;
};

const CompareImage = ({ record }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState<null | CompareImages>(
    null
  );
  const [images, setImages] = useState<Record[]>([]);

  useEffect(() => {
    if (record) {
      let allImages = [record];
      if (record?.compressedPictures) {
        allImages = [...allImages, ...record.compressedPictures];
      }
      if (allImages.length > 1) {
        const leftImage = `${config.URL}${record?.url}`;
        const rightImage = `${config.URL}${allImages[1].url}`;
        setSelectedImages({ leftImage, rightImage });
      }
      setImages(allImages);
    }
  }, [record]);

  const onSelectChange = (event) => {
    const target = event.target.name;
    const value = event.target.value;
    const image = images.find((image) => image.id === value);
    if (!image) return;

    const si = { ...selectedImages };
    // `${config.URL}${sourceValue}`
    switch (target) {
      case 'select-left': {
        si.leftImage = `${config.URL}${image.url}`;
        break;
      }
      case 'select-right': {
        si.rightImage = `${config.URL}${image.url}`;
        break;
      }
      default: {
      }
    }

    // @ts-ignore
    setSelectedImages(si);
  };
  return (
    <>
      <button
        style={{ marginLeft: 10 }}
        onClick={() => setIsOpen(!isOpen)}
        className="btn btn-border"
      >
        Bilder vergleichen
      </button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onBackdropClick={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">Bilder vergleichen</DialogTitle>
        <DialogContent>
          <label htmlFor="select-left">Linkes Bild</label>
          <select id="select-left" name="select-left" onChange={onSelectChange}>
            <option value=""></option>
            {Object.keys(images).map((image) => (
              <option value={images[image].id}>{`${
                images[image].id === record?.id ? 'Original: ' : 'Komprimiert: '
              } ${images[image]?.pictureName} ${
                images[image]?.size ? `${images[image]?.size} KiB` : ''
              }`}</option>
            ))}
          </select>
          <label htmlFor="select-right">Rechtes Bild</label>
          <select
            id="select-right"
            name="select-right"
            onChange={onSelectChange}
          >
            <option value=""></option>
            {Object.keys(images).map((image) => (
              <option value={images[image].id}>{`${
                images[image].id === record?.id ? 'Original: ' : 'Komprimiert: '
              } ${images[image]?.pictureName} ${
                images[image]?.size ? `${images[image]?.size} KiB` : ''
              }`}</option>
            ))}
          </select>
          {selectedImages?.leftImage && selectedImages.rightImage ? (
            <ReactCompareImage
              leftImage={selectedImages.leftImage}
              rightImage={selectedImages.rightImage}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CompareImage;
