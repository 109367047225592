import React, { isValidElement, ReactElement, cloneElement } from 'react';
import { ListControllerProps } from 'react-admin';

interface Props {
  legendData?: Array<{ label: string; color: string }>;
  bulkActionButtons?: false | ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  title?: string;
  handleAdd?: () => void;
}

type LegendProps = Props & ListControllerProps;

const Legend = (props: LegendProps) => {
  const { legendData, title, handleAdd, bulkActionButtons } = props;
  return (
    <>
      <div className={'flex space' + (!title ? ' last' : '')}>
        {title && <h2 className="symbol">{title}</h2>}
        {handleAdd && (
          <div className="flex">
            <button className="btn btn-border btn-no-margin" onClick={handleAdd}>
              Neu
            </button>
          </div>
        )}
        {isValidElement(bulkActionButtons) && cloneElement(bulkActionButtons)}
        {legendData && legendData.length > 0 && (
          <div className={'legend' + (!title ? ' big' : '')}>
            <div className="legend-content">
              {legendData &&
                legendData.map((d) => {
                  if (isValidElement(d)) {
                    return 'Valid Element';
                  } else {
                    return (
                      <p key={d.label}>
                        <span className={`status ${d.color}`}></span>
                        {d.label}
                      </p>
                    );
                  }
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Legend;
