import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { InputProps } from 'react-admin';
import { useField, Field } from 'react-final-form';

import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';

type Either = CreateFormChildrenProps | EditFormChildrenProps;

interface Options {
  label: string;
  value: string;
}
interface AvailableOptions {
  [key: string]: string;
}

const convertOptions = (options: AvailableOptions) => {
  return Object.keys(options).map((key) => ({
    value: key,
    label: options[key],
  }));
};
type Props = InputProps &
  Either & {
    isMulti?: boolean;
    label: string;
    availableOptions: AvailableOptions;
  };

const SelectInputField = (props: Props) => {
  const { isMulti = false, availableOptions, source, label, isEditMode } = props;
  const [selectOptions] = useState(convertOptions(availableOptions));
  const { input, meta } = useField(source);
  const { value, onChange } = input;
  const [currentSelections, setCurrentSelections] = useState<Options | Array<Options> | object>(isMulti ? [] : {});

  // ValueType<OptionType, IsMulti>, actionMeta: ActionMeta<OptionType>
  const onChangeCallback = useCallback(
    (values, action) => {
      // isMulti = false value is an object with label & value
      // isMulti = true value is an array of object with label & value
      onChange(Array.isArray(values) ? values.map((option) => option.value) : values.value);
    },
    [onChange]
  );

  useEffect(() => {
    const cs = Array.isArray(value)
      ? selectOptions.filter((option) => value.includes(option.value))
      : selectOptions.find((option) => option.value === value);
    setCurrentSelections(cs ?? []);
  }, [value, selectOptions]);

  const formatCallback = useCallback(
    (values) => {
      return Array.isArray(values) ? values.map((option) => availableOptions[option]) : availableOptions[values];
    },
    [availableOptions]
  );

  return (
    <>
      {isEditMode === true ? (
        <>
          <p className="grey">{label}</p>
          <label>
            <Select
              isMulti={props.isMulti}
              styles={customStyles}
              options={selectOptions}
              onChange={onChangeCallback}
              value={currentSelections}
              name={props.name ?? ''}
            />
            {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
          </label>
        </>
      ) : null}
      {isEditMode === false ? (
        <>
          <p className="grey">{label}</p>
          <label className={'edit'}>
            <Field name={source} component="input" format={formatCallback} />
            {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
          </label>
        </>
      ) : null}
    </>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 170,
    borderRadius: 0,
    border: '1px solid #E5E5E5',
  }),

  placeholder: (provided) => ({
    ...provided,
    opacity: 1,
    color: '#aaa',
  }),
};

export default SelectInputField;
