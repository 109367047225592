import React, { useCallback } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { InputProps } from 'react-admin';
import { Field } from 'react-final-form';
import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';

interface Props {
  tooltip?: string;
  label: string;
  regex?: RegExp;
}

type TextInputFieldProps = Props & InputProps & (CreateFormChildrenProps | EditFormChildrenProps);

const TextInputField = (props: TextInputFieldProps) => {
  const { isEditMode, source, label, formatData, disabled = false, type = 'text', tooltip } = props;
  const formatCallback = useCallback(
    (value) => {
      return formatData[value] ?? value;
    },
    [formatData]
  );
  const validate = (value: string) => {
    if (!props.regex) return undefined;
    return !props.regex.test(value);
  };
  return (
    <Field
      name={source}
      format={formatData ? formatCallback : undefined}
      validate={validate}
      defaultValue={props.defaultValue ?? undefined}
    >
      {({ input, meta }) => (
        <>
          {tooltip != null ? (
            <Tooltip title={tooltip}>
              <p
                className="grey"
                style={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                {label}
                <InfoIcon color="primary" />:
              </p>
            </Tooltip>
          ) : (
            <p className="grey">{label}:</p>
          )}
          <label className={'edit' + (isEditMode ? ' active' : '')}>
            <input {...input} type={type} {...(disabled ? { disabled: true } : {})} />
            {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
          </label>
        </>
      )}
    </Field>
  );
};

export default TextInputField;
