/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from "react";
import { ListControllerProps, useTranslate } from 'react-admin';

const Pagination = (props: ListControllerProps) => {
  const { page, setPage, total, perPage/*, setPerPage*/ } = props;
    useEffect(() => {
        if (page < 1 || isNaN(page)) {
            setPage(1);
        }
    }, [page, setPage]);
    const translate = useTranslate();
    const getNbPages = () => Math.ceil(total / perPage) || 1;
    const handlePageChange = useCallback(
        (event) => {
            event && event.stopPropagation();
            const page = event.target.dataset.page ?? event.target.parentElement.dataset.page;
            if (page < 0 || page > getNbPages()) {
                throw new Error(
                    translate('ra.navigation.page_out_of_boundaries', {
                        page: page,
                    })
                );
            }
            setPage(page);
        },
        [total, perPage, setPage, translate] // eslint-disable-line react-hooks/exhaustive-deps
    );
    /*
    const handlePerPageChange = useCallback(
        event => {
            setPerPage(event.target.value);
        },
        [setPerPage]
    );
    */
    return (
        <div className="dataTables_paginate paging_simple_numbers">
            <a className={`paginate_button previous ${page <= 1 ? 'disabled' : ''}`} tabIndex={-1} data-page={page - 1} onClick={page <= 1 ? undefined : handlePageChange}>
                <i className="icon icon-chevron-left"></i>
            </a>
            <span>
                {page > 1 && Array(Math.min(3, page - 1)).fill(1).map((_, idx, ar) => {
                    const targetPage = page - ar.length + idx
                    return <a className="paginate_button" tabIndex={0} key={"P-" + targetPage} data-page={targetPage} onClick={handlePageChange}>{targetPage}</a>
                })}

                <a className="paginate_button current" tabIndex={0}>{page}</a>

                {getNbPages() > page && Array(Math.min(3, getNbPages() - page)).fill(1).map((_, idx, ar) => {
                    return <a className="paginate_button" tabIndex={0} key={"P+" + (page + idx + 1)} data-page={page + idx + 1} onClick={handlePageChange}>{page + idx + 1}</a>
                })}
            </span>
            <a className={`paginate_button next ${(page + 1) > getNbPages() ? 'disabled' : ''}`} tabIndex={0} data-page={page + 1} onClick={(page + 1) > getNbPages() ? undefined : handlePageChange}>
                <i className="icon icon-chevron-right"></i>
            </a>
        </div>
    );
}

export default Pagination;