import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import './styles/concat.scss';
import config from './config';

import authProvider from './AuthProvider';
import dataProvider from './DataProvider';

import LoginPage from './Layout/LoginPage';
import LogoutButton from './Layout/LogoutButton';
import Layout from './Layout/Layout';
import Employee from './Resources/Employees';
import Office from './Resources/Offices';
import ProductManager from './Resources/ProductManagers';
import Website from './Resources/Websites';
import Picture from './Resources/Pictures';
import Partner from './Resources/Partners';

const DataProvider = dataProvider(
  config.API_URL!,
  authProvider.getHttpClient()!
);

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const App = () => (
  <Admin
    dataProvider={DataProvider}
    authProvider={authProvider}
    layout={Layout}
    loginPage={LoginPage}
    logoutButton={LogoutButton}
  >
    <Resource {...Employee} />
    <Resource {...Office} />
    <Resource {...ProductManager} />
    <Resource name="cities" options={{ hide: true }} />
    <Resource name="textblocks" options={{ hide: true }} />
    <Resource name="websites" options={{ label: 'Webseiten' }} {...Website} />
    <Resource {...Picture} />
    <Resource {...Partner} />
  </Admin>
);

export default App;
