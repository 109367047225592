import { ResourceProps } from 'react-admin';
import ProductManagerCreate from './ProductManagerCreate';
import ProductManagerEdit from './ProductManagerEdit';
import ProductManagerList from './ProductManagerList';

const Employee: ResourceProps = {
  name: 'productManagers',
  options: { label: 'Produkt Manager' },
  list: ProductManagerList,
  show: ProductManagerEdit,
  create: ProductManagerCreate
};
 
export default Employee;
