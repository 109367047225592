import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Identifier } from 'ra-core';
import { DialogActions, Button } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  file: any;
  onDelete: (id: Identifier) => void;
}

const RemoveCompressedPictureDialog = ({
  isOpen,
  setIsOpen,
  file,
  onDelete,
}: Props) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onBackdropClick={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Bild löschen</DialogTitle>
        <DialogContent>
          <h3>Sicher dass Sie das Bild löschen wollen ?</h3>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setIsOpen(false)}
          >
            Abbrechen
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onDelete(file?.id)}
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveCompressedPictureDialog;
