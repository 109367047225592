import React from 'react';
import get from 'lodash/get';

const currencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});
const formatCurrency = (value) =>
  isNaN(value) ? '' : currencyFormat.format(value);

export const TextField = ({
  record,
  source,
  isCurrency,
  showExapandButton,
  handleToggleExpand,
  defaultValue = '',
  format = null,
}) => {
  const val = get(record, source);
  // @ts-ignore
  const value = typeof format === 'function' ? format(val) : val;
  return (
    <>
      {value == null
        ? defaultValue
        : isCurrency
        ? formatCurrency(value)
        : value}
      {showExapandButton && handleToggleExpand && (
        <i className="icon icon-eye" onClick={handleToggleExpand}></i>
      )}
    </>
  );
};

export default TextField;
