import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  compressPicture: (algorithm: any) => Promise<boolean>;
  btnLabel?: string;
  btnTitle?: string;
}

const imagify = {
  normal: {
    description: ` This mode provides lossless optimization -your images will be optimized without any visible change. If you want perfect quality for your images, we recommend using this mode.

    Note: the file size reduction will be less, compared to aggressive mode.
    `,
  },
  aggressive: {
    description: ` This mode provides perfect optimization of your images without any significant quality loss.

    This will provide a drastic savings on the initial weight, with a small reduction in image quality. Most of the time it's not even noticeable.
    
    If you want the maximum weight reduction, we recommend using this mode. `,
  },
  ultra: {
    description: ` This mode will apply all available optimizations for maximum image compression. This will provide a huge savings on the initial weight. Sometimes the image quality could be degraded a little.

    If you want the maximum weight reduction, and you agree to lose some quality on the images we recommend using this mode. `,
  },
};

const CompressPictureDialog = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [algorithm, setAlgorithm] = useState('normal');

  const onSelectChange = (event) => {
    setAlgorithm(event.target.value);
  };

  const callCP = () => {
    props
      .compressPicture({
        compressMethod: 'imagify',
        params: { [algorithm]: true },
      })
      .then((response) => {
        if (response) {
          setIsOpen(false);
        }
      });
  };
  return (
    <>
      <button onClick={() => setIsOpen(!isOpen)} className="btn btn-border">
        {props.btnLabel ?? 'Bild Komprimieren'}
      </button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onBackdropClick={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {props.btnTitle ?? 'Bild komprimieren'}
        </DialogTitle>
        <DialogContent>
          <p>Imagify Komprimierung</p>
          <p>Algorithmus:</p>
          <select onChange={onSelectChange} defaultValue={algorithm}>
            {Object.keys(imagify).map((key, index) => (
              <option value={key}>{key}</option>
            ))}
          </select>
          <p>{imagify[algorithm].description}</p>
          <input
            onClick={() => callCP()}
            type="button"
            className={'btn btn-blue btn-no-margin'}
            value={props.btnLabel ?? 'Bild komprimieren'}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CompressPictureDialog;
