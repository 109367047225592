// @ts-nocheck
import React, { useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setSidebarVisibility } from 'react-admin';

import LoadingIndicator from '../Components/LoadingIndicator';

const MyAppBar = props => {
    const dispatch = useDispatch();
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    const toggleSidebarCallback = useCallback(() => {
        dispatch(setSidebarVisibility(!open));
    }, [open, dispatch]);

    return (
        <header>
            <div className="burger" onClick={toggleSidebarCallback}>
                <div className="burger-bar">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <a href="/" className="logo" title="Dashboard">
                <img src="/img/kredit-de.svg" alt="Logo von KREDIT DE" title="KREDIT DE" />
            </a>
            <div className="menu">
                <LoadingIndicator />
                {props.logout}
            </div>
        </header>
    );
}



export default MyAppBar;