// @ts-nocheck
import { useDataProvider } from 'ra-core';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '../List/List';
import DataGrid from '../List/DataGrid';
import TextField from '../List/TextField';
import TextInputField from '../Form/TextInputField';

const CityInput = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const prefix = props?.notAddress ? '' : 'address.';
  return (
    <Field name={`${prefix}city`}>
      {({ input, meta }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dataProvider = useDataProvider();

        const onRowClick = (id) => {
          dataProvider.getOne('cities', { id }).then((response) => {
            input.onChange(response.data);
          });
          setIsEdit(!isEdit);
        };
        return (
          <>
            <Dialog
              open={isEdit}
              onClose={() => setIsEdit(false)}
              onBackdropClick={() => setIsEdit(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="xl"
            >
              <DialogTitle id="alert-dialog-title">Stadt auswählen</DialogTitle>
              <DialogContent>
                <List {...props} resource="cities">
                  <DataGrid rowClick={onRowClick}>
                    <TextField source="plz" label="PLZ" />
                    <TextField source="ort" label="Ort" />
                    <TextField source="bundesland" label="Bundesland" />
                  </DataGrid>
                </List>
              </DialogContent>
            </Dialog>
            <TextInputField source={`${prefix}city.ort`} label="Ort" {...props} disabled />
            <TextInputField source={`${prefix}city.plz`} label="PLZ" {...props} disabled />
            <TextInputField source={`${prefix}city.bundesland`} label="Bundesland" {...props} disabled />
            {props.isEditMode == null ||
              (props.isEditMode === true && (
                <>
                  <input
                    onClick={() => setIsEdit(!isEdit)}
                    type="button"
                    className={'btn btn-blue btn-no-margin'}
                    value="Stadt auswählen"
                  />
                  <p></p>
                </>
              ))}
              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
          </>
        );
      }}
    </Field>
  );
};

export default CityInput;
