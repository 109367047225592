import { useListContext } from 'ra-core';
import { Checkbox } from '@material-ui/core';
import { DatagridProps } from 'ra-ui-materialui';
import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
} from 'react';
import DataGridRow from './DataGridRow';
import DataGridHeaderCell from './DataGridHeaderCell';

interface Props {
  tableClassName?: string;
  expandClass?: string;
  bulkActionButtons?:
    | false
    | ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  startingSelectedIds?: Array<any>;
}

export type DataGridProps = DatagridProps & Props;

export const DataGrid = (props: DataGridProps) => {
  const {
    children,
    expand,
    rowClick,
    tableClassName,
    expandClass,
    bulkActionButtons,
    selectedIds = [],
    onSelect,
    onToggleItem,
  } = props;
  const listContext = useListContext(props);

  const {
    ids,
    data,
    basePath,
    currentSort,
    resource,
    setSort,
    filterValues,
    setFilters,
  } = listContext;

  return (
    <div className={tableClassName || 'data-table'}>
      <table className="dashboard" id="table">
        <thead>
          <tr role="row">
            {bulkActionButtons != null && (
              <Checkbox
                color="primary"
                className={`select-item`}
                checked={true}
                onClick={() => {}}
              />
            )}
            {Children.map(children, (field, index) =>
              isValidElement(field) ? (
                <DataGridHeaderCell
                  currentSort={currentSort}
                  field={field}
                  isSorting={
                    currentSort.field ===
                    (field.props.sortBy || field.props.source)
                  }
                  key={field.props.source || index}
                  resource={resource}
                  setSort={setSort}
                  isSearchable={!field.props.notSearchable}
                  isSortable={
                    !field.props.notSearchable || field.props.sortable
                  }
                  filterValues={filterValues}
                  setFilters={setFilters}
                  onSelect={onSelect}
                />
              ) : null
            )}
          </tr>
        </thead>
        <tbody>
          {ids.map((id, rowIndex) =>
            cloneElement(
              <DataGridRow />,
              {
                basePath,
                //hover,
                id,
                key: id,
                //onToggleItem,
                record: data[id],
                resource,
                rowClick,
                expand,
                expandClass,
                onSelect,
                onToggleItem,
                selectedIds,
                bulkActionButtons,
              },
              children
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataGrid;
