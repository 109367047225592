// @ts-nocheck
import * as React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getResources, ReduxState, usePermissions } from 'react-admin';
import MenuItemLink from './MenuItemLink';

const dashboardResource = {
  name: '',
  options: {
    label: 'Dashboard',
  },
};

const Link = ({ res, onMenuClick, exact }) => {
  return (
    <>
      {(res.hasList || res.hasShow) && (
        <MenuItemLink
          key={res.name}
          to={`/${res.name}`}
          text={(res.options && res.options.label) || res.name}
          onClick={onMenuClick}
          exact={exact}
        />
      )}
      {!res.hasList && !res.hasShow && (
        <li key={res.name}>
          <span>{(res.options && res.options.label) || res.name}</span>
        </li>
      )}
      {res.children && (
        <ul>
          {res.children.map((child) => (
            <Link key={child.name} res={child} onMenuClick={onMenuClick} />
          ))}
        </ul>
      )}
    </>
  );
};

const LinkList = React.memo(({ navResources, onMenuClick }) => {
  return (
    <>
      {navResources.map((res) => {
        if (res?.options?.hide == null || res?.options?.hide === false) {
          if (res.header) {
            return (
              <li key={res.name}>
                <span>{(res.options && res.options.label) || res.name}</span>
              </li>
            );
          } else {
            return <Link key={res.name} res={res} onMenuClick={onMenuClick} exact />;
          }
        } else {
          return null;
        }
      })}
    </>
  );
});

const Menu = ({ onMenuClick, hasDashboard }) => {
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const [navResources, setNavResources] = React.useState([]);
  const [adminNavResources, setAdminNavResources] = React.useState([]);
  const resources = useSelector(getResources, shallowEqual);
  const { permissions } = usePermissions();
  React.useEffect(() => {
    const result = {};
    const adminResult = {};
    for (let res of resources) {
      res = { ...res };
      if (res.options.childOf && !res.options.adminLink) {
        let parent = result[res.options.childOf];
        if (!parent) {
          result[res.options.childOf] = {
            header: true,
            name: res.options.childOf,
          };
          parent = result[res.options.childOf];
        }
        if (!parent.children) parent.children = [];
        parent.children.push(res);
      } else if (res.options.adminLink) {
        adminResult[res.name] = res;
      } else {
        result[res.name] = res;
      }
    }
    setNavResources(Object.values(result));
    setAdminNavResources(Object.values(adminResult));
  }, [resources]);
  return (
    <div className={`sidebar ${!open ? 'hide' : ''}`}>
      <nav className="navigation">
        <ul>
          {hasDashboard && <Link key="dashboard" res={dashboardResource} onMenuClick={onMenuClick} exact />}
          <LinkList navResources={navResources} onMenuClick={onMenuClick} />
        </ul>
      </nav>
      <div className="adminLinks">
        {permissions?.includes('admin') &&
          adminNavResources &&
          adminNavResources.map((res) => {
            if (!res.hasList && !res.hasShow) return null;
            return (
              <MenuItemLink
                key={res.name}
                to={`/${res.name}`}
                text={(res.options && res.options.label) || res.name}
                onClick={onMenuClick}
                exact
              />
            );
          })}
      </div>
    </div>
  );
};

export default Menu;
