// @ts-nocheck
import React, { useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '../List/List';
import DataGrid from '../List/DataGrid';
import TextField from '../List/TextField';
import TextInputField from '../Form/TextInputField';

const ManagerInput = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <Field name="manager">
      {({ input, meta }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dataProvider = useDataProvider();

        const onRowClick = (id) => {
          dataProvider.getOne('employees', { id }).then((response) => {
            input.onChange(response.data);
          });
          setIsEdit(!isEdit);
        };
        return (
          <>
            <Dialog
              open={isEdit}
              onClose={() => setIsEdit(false)}
              onBackdropClick={() => setIsEdit(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="xl"
            >
              <DialogTitle id="alert-dialog-title">Manager auswählen</DialogTitle>
              <DialogContent>
                <List {...props} resource="employees">
                  <DataGrid rowClick={onRowClick}>
                    <TextField source="firstName" label="Vorname" />
                    <TextField source="lastName" label="Nachname" />
                    <TextField source="email" label="email" />
                  </DataGrid>
                </List>
              </DialogContent>
            </Dialog>
            <TextInputField source="manager.firstName" label="Vorname" {...props} disabled />
            <TextInputField source="manager.lastName" label="Nachname" {...props} disabled />
            <TextInputField source="manager.email" label="Email" {...props} disabled />
            {props.isEditMode == null ||
              (props.isEditMode === true && (
                <>
                  <input
                    onClick={() => setIsEdit(!isEdit)}
                    type="button"
                    className={'btn btn-blue btn-no-margin'}
                    value="Manager auswählen"
                  />
                  <p></p>
                </>
              ))}
          </>
        );
      }}
    </Field>
  );
};

export default ManagerInput;
