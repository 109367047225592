import React from 'react';
import { useCreateController } from 'react-admin';

import CreateForm from '../../Components/Form/CreateForm';
import TextInputField from '../../Components/Form/TextInputField';
import SubmitButton from '../../Components/Form/SubmitButton';

const WebsiteCreate = (props) => {
  const createProps = useCreateController({ ...props, undoable: false, redirect: 'list' });
  return (
    <div className={'details'}>
      <div className="flex space">
        <h2 className="symbol">Webseite Anlegen</h2>
      </div>
      <CreateForm {...createProps}>
        {(formProps) => {
          return (
            <div className="flex row">
              <div className="flex-item">
                <div className={'shadow-box overview'}>
                  <h3 className="underlined">Allgemein</h3>
                  <div className="details-info">
                    <TextInputField source="domain" label="Domain" {...formProps} />
                  </div>
                </div>
                <SubmitButton {...formProps} isLeft={true} />
              </div>
            </div>
          );
        }}
      </CreateForm>
    </div>
  );
};

export default WebsiteCreate;
