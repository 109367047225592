import React from 'react';
import { useCreateController } from 'react-admin';
import CityInput from '../../Components/Form/CityInput';
import CreateForm, { CreateFormChildrenProps } from '../../Components/Form/CreateForm';
import SubmitButton from '../../Components/Form/SubmitButton';
import TextInputField from '../../Components/Form/TextInputField';
import PostPartnerSchema from './schemas/postPartner.json';

const PartnerCreate = (props) => {
  const createProps = useCreateController(props);

  return (
    <div className="sell details">
      <div className="flex space">
        <h2 className="symbol">Partner Anlegen</h2>
      </div>
      <CreateForm {...createProps} validationSchema={PostPartnerSchema}>
        {(props: CreateFormChildrenProps) => {
          return (
            <div className="flex row">
              <div className="flex-item">
                <div className={'shadow-box overview'}>
                  <h3 className="underlined">Übersicht</h3>
                  <div className="details-info">
                    <TextInputField {...props} source="firstName" label="Vorname" />
                    <TextInputField {...props} source="lastName" label="Nachname" />
                    <TextInputField {...props} source="email" label="E-Mail" />
                    <TextInputField {...props} source="phoneNumber" label="Telefonnummer" />
                    <TextInputField {...props} source="organisation" label="Firma / Organisation" />
                  </div>
                </div>
              </div>
              <div className="flex-item">
                <div className={'shadow-box overview'}>
                  <h3 className="underlined">Adresse</h3>
                  <div className="details-info">
                    <CityInput {...createProps} {...props} notAddress={true} />
                    <TextInputField {...props} source="street" label="Straße" />
                  </div>
                </div>
              </div>
              <SubmitButton {...props} isLeft={true} />
            </div>
          );
        }}
      </CreateForm>
    </div>
  );
};

export default PartnerCreate;
