import React from 'react';
import { InputProps } from 'react-admin';
import { Field } from 'react-final-form';
import { CreateFormChildrenProps } from './CreateForm';
import { EditFormChildrenProps } from './EditForm';

interface Props {
  label: string;
}

type TextInputFieldProps = Props & InputProps & (CreateFormChildrenProps | EditFormChildrenProps);

const MidInputField = (props: TextInputFieldProps) => {
  const { isEditMode, source, label, disabled = false, type = 'text' } = props;

  return (
    <Field name={source}>
      {({ input, meta }) => (
        <>
          <p className="grey">{label}:</p>
          <label className={'edit' + (isEditMode ? ' active' : '')}>
            <input {...input} type={type} {...(disabled ? { disabled: true } : {})} style={{ borderColor: meta.valid ? 'green' : 'red' }} />
            {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
          </label>
        </>
      )}
    </Field>
  );
};

export default MidInputField;
